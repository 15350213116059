import {Component, OnInit, Output, Renderer2, Input, EventEmitter, ChangeDetectorRef, AfterViewInit} from '@angular/core';
import {TripInfoComponent} from '../trip-info.component';
import {Router} from '@angular/router';
import {MapService} from '../../services/map.service';
import {TripService} from '../../services/trip.service';
import {ToolSetService} from '../../services/tool-set.service';
import {formatDate} from '@angular/common';
import {Monument, ToastMaker} from '../../models/models.map';
import {DirectionService} from '../../services/direction.service';
import {SearchService} from '../../services/search.service';
import {of} from 'rxjs';
import * as moment from 'moment';
import {CdkDragDrop, CdkDragStart} from '@angular/cdk/drag-drop';
import {DeviceService} from 'src/app/shared/device.service';
import {LocalStorageService} from 'src/app/shared/local-storage.service';
import {ToastService} from '../../../shared/toast.service';

@Component({
  selector: 'app-day-avatar',
  templateUrl: './day-avatar.component.html',
  styleUrls: ['./day-avatar.component.scss']
})
export class DayAvatarComponent extends TripInfoComponent implements OnInit, AfterViewInit {

  @Input('dayindex') dayindex: number;

  @Output('showOnlyObjectsChild') showOnlyObjectsChild = new EventEmitter<any>();

  public infoDay: any;

  public dateDay;
  public lastMove = null;

  public toggleTime = false;
  public timeBegin = null;
  public config = {
    locale: 'pl',
    showTwentyFourHours: true
  };

  public platform = null;

  public mobileData = null;

  public nameZone = null;

  public strArrayDropZone = null;

  public monumentsArray: Array<Monument> = [];

  constructor(
    public router: Router,
    public renderer: Renderer2,
    public mapService: MapService,
    public tripService: TripService,
    public toolSetService: ToolSetService,
    public directionService: DirectionService,
    public searchService: SearchService,
    public cdRef: ChangeDetectorRef,
    public deviceService: DeviceService,
    public storage: LocalStorageService,
    public toast: ToastService
  ) {
    super(router, mapService, renderer, tripService, toolSetService, directionService, storage, toast);
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.infoDay = this.tripService.getDay(this.dayindex);
    this.getMonuments();
    this.timeBegin = this.getStartTime();

    this.toolSetService.timeSubject.subscribe(answer => {
      if (answer === this.dayindex) {
        this.timeBegin = this.getStartTime();
      }
    });

    this.nameZone = this.getDropListName();

    this.strArrayDropZone = ['listDrag0', 'listDrag1', 'listDrag2', 'listDrag3', 'listDrag4', 'listDrag5', 'listDrag6', 'listDrag7', 'listDrag8'];

  }

  public imageLoader(target) {
    target.parentElement.classList.remove('loading');
  }

  /** pokazuje obiekty na mapie */
  public renderDayObject() {
    this.mapService.renderFist.next({'renderFirst': false, 'lastDayRender': this.dayindex});
  }


  /** komunikacja miedzy avatarem dnia a szczegolami */
  public writeDay() {
    this.tripService.indexOfDay.next(this.dayindex);
    this.directionService.directionSubject.next(this.dayindex);
    this.renderDayObject();
    this.selectCurrentDay(this.dayindex);
    this.searchService.closeCheckBox.next({close: true});
    this.showOnlyObjectsChild.emit(this.monumentsArray);

  }

  public openSearch() {
    this.mapService.toggleMenuItemObs.next({state: true, action: 'ToolBar'});
  }

  public setDay() {
    return formatDate(this.toolSetService.getNextDay(this.dayindex), 'dd.MM.yyyy', 'en');
  }

  public getNameDay() {
    const days = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
    const nextDay = this.toolSetService.getNextDay(this.dayindex);
    const nameDay = nextDay.getDay();
    return days [nameDay];
  }

  public getMonuments() {
    const tmp = this.tripService.getDayMonuments(this.dayindex);
    if (Array.isArray(tmp)) {
      this.monumentsArray = tmp;
    }
  }

  public getStartHour() {
    const hour = this.tripService.getDay(this.dayindex).time_start;
    if (!!hour) {
      this.tripService.setDayStart(this.dayindex, '8:00');
      return '8:00';
    } else {
      return hour;
    }
  }

  public dragstart_handler(ev, i, monument_id, accommodation) {
    ev.dataTransfer.setData('text/plain/index', i);
    ev.dataTransfer.setData('text/plain/day', this.dayindex);
    ev.dataTransfer.setData('text/plain/monument_id', monument_id);
    ev.dataTransfer.setData('text/plain/accommodation', accommodation);
  }

  public dragleave_handler(ref) {
    this.renderer.removeClass(ref, 'hoverClass');

  }

  public dragover_handler(event, ref) {
    event.preventDefault();
    this.renderer.addClass(ref, 'hoverClass');
  }

  public drop(event, i, ref = null) {
    event.preventDefault();
    const index = parseInt(event.dataTransfer.getData('text/plain/index'), 10);
    const indexDayDrag = parseInt(event.dataTransfer.getData('text/plain/day'), 10);
    const monument_id = parseInt(event.dataTransfer.getData('text/plain/monument_id'), 10);
    const accommodation = parseInt(event.dataTransfer.getData('text/plain/accommodation'), 10);

    const tripArray: Array<Monument> = (!!this.tripService.getDayMonuments(index)) ?
      this.tripService.getDayMonuments(index) : [];

    if (tripArray.length < 25) {
      const dropEl = this.tripService.getDayMonument(this.dayindex, i);
      if (dropEl.accommodation === 1 || accommodation === 1) {
        this.toast.subjectToast.next(ToastMaker.create({
          message: 'Nie można zmienić kolejności obiektu noclegowego.',
          time: 4000,
          type: 'warning'
        }));
        this.renderer.removeClass(ref, 'hoverClass');
      } else if (accommodation === 0) {
        const isObject = this.monumentsArray.find(obj => {
          return obj['id_objects'] === monument_id;
        });
        if (!!isObject && this.dayindex !== indexDayDrag) {
          this.toast.subjectToast.next(ToastMaker.create({
            message: 'Ten obiekt jest już dodany do wybranego dnia.',
            time: 4000,
            type: 'warning'
          }));
        } else {
          if (this.dayindex === indexDayDrag) {
            this.monumentsArray = this.array_move(this.monumentsArray, index, i);
            this.tripService.setDayMonuments(this.monumentsArray, this.dayindex);
          } else {
            const tmpObj = this.tripService.getDayMonument(indexDayDrag, index);
            this.monumentsArray.splice(i, 0, tmpObj);
            this.tripService.setDayMonuments(this.monumentsArray, this.dayindex);
            const oldDay = this.tripService.getDayMonuments(indexDayDrag);
            oldDay.splice(index, 1);
          }
        }
        if (!!ref) {
          this.renderer.removeClass(ref, 'hoverClass');
        }
        this.directionService.directionSubject.next(this.dayindex);
        this.renderDayObject();
      }

    } else {
      this.toast.subjectToast.next(ToastMaker.create({
        message: 'Nie można dodać więcej niż 25 obiektów do jednego dnia wycieczki.',
        time: 4000, type: 'warning'
      }));
    }
  }

  public dropOnPlus(event) {
    if (Array.isArray(this.monumentsArray) && this.monumentsArray.length === 0) {
      const index = parseInt(event.dataTransfer.getData('text/plain/index'), 10);
      const indexDayDrag = parseInt(event.dataTransfer.getData('text/plain/day'), 10);

      const tmpObj = this.tripService.getDayMonument(indexDayDrag, index);
      this.monumentsArray.push(tmpObj);
      this.tripService.setDayMonuments(this.monumentsArray, this.dayindex);
      const oldDay = this.tripService.getDayMonuments(indexDayDrag);
      oldDay.splice(index, 1);
    }
  }

  public dragOverOnPlus(event) {
    event.preventDefault();

  }

  public array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }


  public renderMode() {
    this.tripService.setDayMode(this.dayindex);
    this.directionService.directionSubject.next(this.dayindex);
    this.renderDayObject();
  }

  public optimize(): void {
    const modeOfTransport = this.tripService.getDayMode(this.dayindex);
    const roadParam = this.directionService.prepareParam(this.monumentsArray);
    let getOptimizeRoadFromGoogleMaps = null;
    if (!!roadParam) {
      this.directionService.getRoadFromGoogleMapsOptimize(roadParam, modeOfTransport).subscribe(response => {
        // response = response.data;
        if (this.deviceService.getPlatform() === 'browser') {
          response = response.data;
        }
        if (response.status === 'OK') {
          getOptimizeRoadFromGoogleMaps = response['routes'][0].waypoint_order;
          const tmpDayMonumnets = this.tripService.getDayMonuments(this.dayindex);
          const optimize = new Array(tmpDayMonumnets.length - 2);
          let tmpNewMonuments: Array<Monument> = tmpDayMonumnets.slice(1, tmpDayMonumnets.length - 1);
          for (let i = 0; i < optimize.length; i++) {
            for (let j = 0; j < getOptimizeRoadFromGoogleMaps.length; j++) {
              if (getOptimizeRoadFromGoogleMaps[j] === i) {
                optimize[j] = tmpNewMonuments[getOptimizeRoadFromGoogleMaps[i]];
              }
            }
          }
          optimize.unshift(tmpDayMonumnets[0]);
          optimize.push(tmpDayMonumnets[tmpDayMonumnets.length - 1]);
          this.tripService.setDayMonuments(optimize, this.dayindex);
          this.monumentsArray = optimize;
          this.directionService.directionSubject.next(this.dayindex);
          this.renderDayObject();

        } else {

        }

      });
    }
  }

  public toggleTimeFun() {
    this.toggleTime = !this.toggleTime;
  }

  public onTimeChange(event) {
    const date = new Date(event.date);
    const hours = date.getHours();
    const minutes = (date.getMinutes().toString().length === 1) ? '0' + date.getMinutes() : date.getMinutes();
    this.tripService.setDayStart(this.dayindex, hours + ':' + minutes);
    this.timeBegin = this.getStartTime();
  }

  public getStartTime() {
    const day = this.tripService.getDay(this.dayindex);
    if (!!day) {
      if ((day.time_start === null) || (day.time_start === ':')) {
        this.tripService.setDayStart(this.dayindex, '8:00');
        return moment('8:00', 'HH:mm');
      } else {
        return moment(this.tripService.getDayStart(this.dayindex), 'HH:mm');
      }
    }
    return null;
  }


  public ShowHourStart() {
    if (!!this.timeBegin) {
      const hours = (this.timeBegin.hours().toString().length === 1) ? '0' + this.timeBegin.hours() : this.timeBegin.hours();
      const minutes = (this.timeBegin.minutes().toString().length === 1) ? '0' + this.timeBegin.minutes() : this.timeBegin.minutes();
      return hours + ':' + minutes;
    } else {
      return null;
    }
  }

  public dropMobile(event: CdkDragDrop<string[]>, nameZone) {


    const index = event.previousIndex;
    const i = event.currentIndex;
    const accommodation = event.item.data.accommodation;
    const indexDayDrag = event.item.data.day;
    const monument_id = event.item.data.id;

    const tripArray: Array<Monument> = (!!this.tripService.getDayMonuments(index)) ?
      this.tripService.getDayMonuments(index) : [];

    if (tripArray.length < 25) {

      const isObject = this.monumentsArray.find(obj => {
        return obj['id_objects'] === monument_id;
      });

      const objIsAccomondation = this.tripService.getDayMonument(this.dayindex, i);

      if (!!objIsAccomondation) {
        if (accommodation === 1 || objIsAccomondation.accommodation === 1) {
          this.toast.subjectToast.next(ToastMaker.create({
            message: 'Nie można zmienić kolejności obiektu noclegowego.',
            time: 4000,
            type: 'error'
          }));
        } else if (accommodation === 0) {
          if (event.container.id === event.previousContainer.id) {
            this.monumentsArray = this.array_move(this.monumentsArray, index, i);
            this.tripService.setDayMonuments(this.monumentsArray, this.dayindex);
          } else if (!isObject) {
            const tmpObj = this.tripService.getDayMonument(indexDayDrag, index);
            this.monumentsArray.splice(i, 0, tmpObj);
            this.tripService.setDayMonuments(this.monumentsArray, this.dayindex);
            const oldDay = this.tripService.getDayMonuments(indexDayDrag);
            oldDay.splice(index, 1);
          } else {
            this.toast.subjectToast.next(ToastMaker.create({
              message: 'Ten obiekt jest już dodany do wybranego dnia.',
              time: 4000,
              type: 'error'
            }));
          }
        }
      } else if (i === 0 || i === this.monumentsArray.length) {
        const tmpObj = this.tripService.getDayMonument(indexDayDrag, index);
        if (i === 0) {
          this.monumentsArray.unshift(tmpObj);
        }
        if (i === this.monumentsArray.length) {
          this.monumentsArray.push(tmpObj);
        }
        this.tripService.setDayMonuments(this.monumentsArray, this.dayindex);
        const oldDay = this.tripService.getDayMonuments(indexDayDrag);
        oldDay.splice(index, 1);
      }

      this.directionService.directionSubject.next(this.dayindex);
      this.renderDayObject();


    } else {
      this.toast.subjectToast.next(ToastMaker.create({
        message: 'Nie można dodać więcej niż 25 obiektów do jednego dnia wycieczki.',
        time: 4000,
        type: 'warning'
      }));
    }

  }

  public dragMobile(event: CdkDragStart<string[]>, item: Monument) {
    this.mobileData = {id: item.id_objects, accommodation: item.accommodation, day: this.dayindex};
  }

  public getDropListName(): string {
    return 'listDrag' + this.dayindex;
  }

  public generateListDrop() {

    let numerOfDays = this.tripService.getLengthDays();
    const array: Array<string> = [];
    let tmp = null;
    for (let i = 0; i < numerOfDays; i++) {
      if (i !== this.dayindex) {
        array.push('listDrag' + i);
        tmp = 'listDrag' + i;
      }
    }
    return array;
  }


}


// > a = [1,2,3,4,5]
// [ 1, 2, 3, 4, 5 ]
// > b = [11,12,13,14]
// [ 11, 12, 13, 14 ]
// > index = a.indexOf(3)
// 2
// > a.splice(index, 0, ...b)
// []
// > a.splice(index, 0, b)
// []
// > a
// [ 1, 2, [ 11, 12, 13, 14 ], 11, 12, 13, 14, 3, 4, 5 ]
// > a = [1,2,3,4,5]
// [ 1, 2, 3, 4, 5 ]
// > a.splice(index, 0, b)
// []
// > a
// [ 1, 2, [ 11, 12, 13, 14 ], 3, 4, 5 ]
// > a = [1,2,3,4,5]
// [ 1, 2, 3, 4, 5 ]
// > a.splice(index, 0, ...b)
// []
// > a
// [ 1, 2, 11, 12, 13, 14, 3, 4, 5 ]
// > a = [1,2,3,4,5]
// [ 1, 2, 3, 4, 5 ]
// > a.splice(index+1, 0, ...b)
// []
