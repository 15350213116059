import {Component, OnInit, Input, ViewChild, Renderer2, ElementRef} from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  constructor(
    public renderer: Renderer2
  ) {
  }

  public contentToast: string;

  @ViewChild('toast') toastDom: ElementRef;

  @Input('content') set content(res: any) {
    if (res) {
      this.contentToast = res.message;
      this.renderer.addClass(this.toastDom.nativeElement, res.type);
    }
  }

  ngOnInit() {
  }

}
