import {Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';
import {ApiService} from '../shared/api.service';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {

  @ViewChild('rules') rules: ElementRef;

  @Input('menuVisible') menuVisible: Boolean;

  public content;
  public title = '';

  public rulesStatus = false;

  constructor(
    private apiService: ApiService
  ) {
  }

  ngOnInit() {

    this.apiService.pageObs.subscribe(res => {
      if (res === '3') {
        if (this.title === '') {
          this.apiService.getPage('6').subscribe(response => {
            if (response.status['success']) {
              this.content = response['data']['page']['content'];
              this.title = response['data']['page']['name'];
              this.rulesStatus = true;
            }
          });
        } else {
          this.rulesStatus = true;
        }

      } else {
        this.rulesStatus = false;
      }
    });
  }

  public close() {
    this.apiService.pageObs.next(null);
  }

}
