/**
 * Zmienne:
 *  static  apiUrl =  adres url do API;
 getApiUrl() - zwraca flagę zmiany trybów
 */
export class ConfigModel {

  static apiUrl = 'https://najciekawszenawsi.skycms.com.pl/multimedia-atlas/';

  static apiMainUrl = 'https://najciekawszenawsi.skycms.com.pl/';

  static baseUrl = 'https://najciekawszenawsi.s1.netkoncept.com/';

  constructor() {
  }

  public static getApiUrl(): string {
    return this.apiUrl;
  }

  public static getapiMainUrl(): string {
    return this.apiMainUrl;
  }

  public static getBaseUrl(): string {
    return this.baseUrl;
  }
}
