import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

declare var device;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {


  constructor() {
  }

  /**pobiera model urzadzenia */
  public getModel(): string {
    if (typeof device === 'undefined') {
      return 'browser';
    }
    return device.model;
  }

  /** pobiera nazwe platformy */
  public getPlatform(): string {
    if (typeof device === 'undefined') {
      return 'browser';
    }

    return device.platform;
  }

  /** pobiera device ID */
  public getDeviceUuid(): string {
    if (typeof device === 'undefined') {
      return null;
    }
    return device.uuid;
  }

  /** pobiera versje urządzenia */
  public getDeviceVersion(): string {
    if (typeof device === 'undefined') {
      return null;
    }
    return device.version;
  }

}
