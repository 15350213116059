import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { ApiService } from '../shared/api.service';

@Component({
  selector: 'app-bibliography',
  templateUrl: './bibliography.component.html',
  styleUrls: ['./bibliography.component.scss']
})
export class BibliographyComponent implements OnInit {

  @ViewChild('bibliography') bibliography: ElementRef;

  @Input('menuVisible') menuVisible: Boolean;

  public content;
  public title = '';

  public bibliographyStatus = false;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {

    this.apiService.pageObs.subscribe(res => {
      if (res === '2') {
        if (this.title === '') {
          this.apiService.getPage('479').subscribe(response => {
            if (response.status['success']) {
              this.content = response['data']['page']['content'];
              this.title = response['data']['page']['name'];
              this.bibliographyStatus = true;
            }
          });
        } else {
          this.bibliographyStatus = true;
        }

      } else {
        this.bibliographyStatus = false;
      }
    });
  }

  public close() {
    this.apiService.pageObs.next(null);
  }

}
