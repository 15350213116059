import {Component, OnInit, ViewChild, Renderer2, OnDestroy, Output, EventEmitter, ElementRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MapService} from '../services/map.service';
import {TripService} from '../services/trip.service';
import {ApiService} from 'src/app/shared/api.service';
import {ToolSetService} from '../services/tool-set.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {LocalStorageService} from '../../shared/local-storage.service';
import {ShareButtons} from '@ngx-share/core';
import {Subscription, Subject} from 'rxjs';
import {ToastMaker} from '../models/models.map';
import {ToastService} from '../../shared/toast.service';
import {DeviceService} from '../../shared/device.service';

declare var window;

@Component({
  selector: 'app-save-form',
  templateUrl: './save-form.component.html',
  styleUrls: ['./save-form.component.scss'],
})

export class SaveFormComponent implements OnInit, OnDestroy {

  @ViewChild('navLeft') navLeft;
  @ViewChild('password') passwordView;
  @Output('printTripAction') printTripAction = new EventEmitter<any>();

  public toggleFlag = false;
  public actionName = 'SaveForm';
  public idTrip: any;
  public trip: any;

  public sub = new Subscription();
  public subscription = new Subscription();

  public savedFlag: boolean = false;
  public savedTrips;
  public tripSaveForm: FormGroup;
  public shareByEmail: FormGroup;
  public publishForm: FormGroup;

  public shareAction: string;

  public urlMap: string = null;
  public tripName: string;
  public iframeUrl: string;

  public debouncer = new Subject();
  public phonePattern = '^[0-9]{8,13}';

  formErrors = {
    name: '',
    regulamin: ''
  };

  formShareErrors = {
    name: '',
    email: '',
    regulamin: ''
  };

  formPublishErrors = {
    name: '',
    phone: '',
    email: '',
    regulamin: ''
  };

  private validationMessages = {
    name: {
      required: 'Nazwa wycieczki jest wymagana',
      minlength: 'Nazwa wycieczki musi składać się z min. 3 znaków'
    },
    regulamin: {
      required: 'Wymagana jest akceptacja polityki prywatności'
    }
  };

  private validationShareMessages = {
    name: {
      required: 'Imię jest wymagane',
      minlength: 'Imię musi składać się z min. 3 znaków'
    },
    email: {
      required: 'E-mail jest wymagany',
      email: 'Podaj prawidłowy adres e-mail'
    },
    regulamin: {
      required: 'Wymagana jest akceptacja polityki prywatności'
    }
  };

  private validationPublishMessages = {
    name: {
      required: 'Imię jest wymagane',
      minlength: 'Imię musi składać się z min. 3 znaków'
    },
    phone: {
      pattern: 'Nie prawidłowy numer telefonu'
    },
    email: {
      required: 'E-mail jest wymagany',
      email: 'Podaj prawidłowy adres e-mail'
    },
    regulamin: {
      required: 'Wymagana jest akceptacja polityki prywatności'
    }
  };

  constructor(
    public router: Router,
    public mapService: MapService,
    public tripService: TripService,
    public renderer: Renderer2,
    public apiService: ApiService,
    public toolSetService: ToolSetService,
    public formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public share: ShareButtons,
    public deviceService: DeviceService,
    public toast: ToastService
  ) {

    this.debouncer.pipe(debounceTime(500)).subscribe(res => {
      this.printTripAction.emit(true);
    });
    // .subscribe((val) => this.outerValuedChanged.emit(value));
  }

  ngOnInit() {
    this.shareAction = 'close';

    /** Sprawdzenie czy wycieczka jest już zapisana*/
    this.tripService.isSavedTripObs.subscribe(res => {
      this.savedFlag = res;
    });


    this.subscription.add(
      this.mapService.toggleMenuItemObs.subscribe(info => {
        if (info.state && info.action === this.actionName) {
          this.open();
        } else {
          this.hide();
        }
      })
    );


    if (this.deviceService.getPlatform() === 'browser') {
      /** Desktop */
      this.tripSaveForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        regulamin: ['', [Validators.requiredTrue]],
        recaptchaSave: ['', [Validators.required]]
      });

      this.shareByEmail = this.formBuilder.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        email: ['', [Validators.required, Validators.email]],
        content: [''],
        regulamin: ['', [Validators.requiredTrue]],
        recaptchaEmail: ['', [Validators.required]]
      });

      this.publishForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        phone: [''],
        email: ['', [Validators.required, Validators.email]],
        content: [''],
        regulamin: ['', [Validators.requiredTrue]],
        recaptchaPublish: ['', [Validators.required]]
      });

    } else {
      /** Mobile */
      this.tripSaveForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        regulamin: ['', [Validators.requiredTrue]]
      });

      this.shareByEmail = this.formBuilder.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        email: ['', [Validators.required, Validators.email]],
        content: [''],
        regulamin: ['', [Validators.requiredTrue]]
      });

      this.publishForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        phone: [''],
        email: ['', [Validators.required, Validators.email]],
        content: [''],
        regulamin: ['', [Validators.requiredTrue]]
      });
    }

    this.tripSaveForm.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.onValueChanged();
    });

    this.shareByEmail.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.onValueChangedByEmail();
    });

    this.publishForm.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.onValueChangedPublish();
    });

    this.onValueChanged();

    this.onValueChangedByEmail();

    this.onValueChangedPublish();

    this.tripService.tripNameObs.subscribe(res => {
      this.tripName = res;
    });

    this.iframeUrl = this.apiService.apiMainUrl + '8/multimedia-atlas.html?hash=' + this.idTrip;

    this.tripService.iframeUrlObs.subscribe(res => {
      this.iframeUrl = res;
    });

    this.urlMap = this.apiService.baseUrl + '#/map/' + this.idTrip;
    this.tripService.tripURLObs.subscribe(res => {
      this.urlMap = res;
    });
  }

  ngOnDestroy() {
    this.tripService.setTrip({});
    this.trip = null;
    this.sub.unsubscribe();
    this.subscription.unsubscribe();
  }

  public hide(): void {
    if (this.toggleFlag === true) {
      this.toggleFlag = false;
      this.renderer.addClass(this.navLeft.nativeElement, 'closed');
    }

  }

  public open() {
    this.apiService.showLoader();

    this.toggleFlag = true;
    this.renderer.removeClass(this.navLeft.nativeElement, 'closed');

    this.idTrip = this.tripService.getTripId();
    this.trip = this.tripService.getTrip();
    this.tripService.tripNameObs.next(this.trip.name);

    this.tripSaveForm.patchValue({
      name: this.tripName
    });

    if (this.tripService.checkKeyKK(this.tripService.getTripId()) && (this.trip.name !== null)) {
      /** zapis wycieczki */
      this.sub = this.tripService.saveTrip();
    }
    this.apiService.hideLoader();
  }

  public printTrip() {
    this.apiService.showLoader();
    this.debouncer.next(true);
  }

  public getGPX(): void {
    this.apiService.showLoader();
    let subscription = new Subscription();
    subscription = this.apiService.getTripGPXUrl(this.tripService.getTripId()).subscribe(res => {
      this.toolSetService.exportTripBrowser(res);
      this.apiService.hideLoader();
    }).add(subscription.unsubscribe());
  }

  public getKML(): void {
    this.apiService.showLoader();
    let subscription = new Subscription();
    this.apiService.getTripKMLUrl(this.tripService.getTripId()).subscribe(res => {
      this.toolSetService.exportTripBrowser(res);
      this.apiService.hideLoader();
    }).add(subscription.unsubscribe());
  }

  public rules(): void {
    this.apiService.pageObs.next('3');
  }

  /** Formularz zapisania wycieczki*/
  public submitTrip(): void {
    if (this.passwordView.nativeElement.value === '') {
      this.apiService.showLoader();
      const trip = this.trip;
      const formValue = this.tripSaveForm.value;
      const saveKey = this.tripService.getKeyKK(trip.id_trips);
      trip.save_key = saveKey;
      const result = {...trip, ...formValue};

      /** zapis wycieczki */
      this.tripSaveForm.reset();
      this.sub = this.tripService.saveTrip(result);
    }
  }

  public onValueChanged(): void {
    const form = this.tripSaveForm;

    for (let field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const validationMessages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += validationMessages[key] + ' ';
        }
      }
    }
  }

  /** Formularz udostępniania przez e-maila*/
  public sendByEmail() {
    if (this.passwordView.nativeElement.value === '') {
      this.apiService.showLoader();
      this.apiService.shareByEmail(this.tripService.getTripId(), this.shareByEmail.value).subscribe(res => {
        if (res.status['success']) {
          this.shareByEmail.reset();
          this.shareAction = 'close';
          this.toast.subjectToast.next(ToastMaker.create({
            message: 'Wiadomość została wysłana.',
            time: 4000,
            type: 'success'
          }));
          this.apiService.hideLoader();
        } else {
          this.toast.subjectToast.next(ToastMaker.create({
            message: 'Nie udało się wysłać wiadomości. Skontaktuj się z nami, aby wyjaśnić sytuację.',
            time: 4000,
            type: 'error'
          }));
          this.apiService.hideLoader();
        }

      });
    }
  }

  public onValueChangedByEmail(): void {
    const form = this.shareByEmail;

    for (let field in this.formShareErrors) {
      this.formShareErrors[field] = '';
      let control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const validationMessages = this.validationShareMessages[field];
        for (const key in control.errors) {
          this.formShareErrors[field] += validationMessages[key] + ' ';
        }
      }
    }
  }

  /** Formularz zgłoszenia do opublikowania*/
  public publishTrip() {
    if (this.passwordView.nativeElement.value === '') {
      this.apiService.showLoader();
      this.apiService.publishTrip(this.tripService.getTripId(), this.publishForm.value).subscribe(res => {
        if (res.status['success']) {
          this.publishForm.reset();
          this.shareAction = 'close';
          this.toast.subjectToast.next(ToastMaker.create({
            message: 'Wycieczka została zgłoszona do opublikowania, dziękujemy.',
            time: 4000,
            type: 'success'
          }));
          this.apiService.hideLoader();
        } else {
          this.toast.subjectToast.next(ToastMaker.create({
            message: 'Nie udało się wysłać zgłoszenia. Skontaktuj się z nami, aby wyjaśnić sytuację.',
            time: 4000,
            type: 'error'
          }));
          this.apiService.hideLoader();
        }
      });
    }
  }

  public onValueChangedPublish(): void {
    const form = this.publishForm;

    for (let field in this.formPublishErrors) {
      this.formPublishErrors[field] = '';
      let control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const validationMessages = this.validationPublishMessages[field];
        for (const key in control.errors) {
          this.formPublishErrors[field] += validationMessages[key] + ' ';
        }
      }
    }
  }

  public shareButton() {
    window.plugins.socialsharing.share(null, null, null, this.urlMap);
  }

  public copyToClipboard(elem) {
    elem.select();
    elem.setSelectionRange(0, elem.value.length);
    document.execCommand('copy');
    this.toast.subjectToast.next(ToastMaker.create({message: 'Kod został skopiowany.', time: 4000, type: 'info'}));
  }

}
