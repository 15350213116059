import {Component, OnInit, HostBinding, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../shared/api.service';
import {TripService} from '../map/services/trip.service';

@Component({
  selector: 'app-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss']
})
export class MenuLeftComponent implements OnInit {

  public iconsBaseUrl = 'assets/images/';

  @HostBinding('class.opened')
  isOpened = false;

  public rulesStatus = false;
  public contactStatus = false;
  public bibliographyStatus = false;
  public pageActive = null;
  public newTripBtn = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    public tripService: TripService
  ) {
  }

  ngOnInit() {
    this.apiService.pageObs.subscribe(res => {
      this.pageActive = res;
    });

    this.tripService.newTripBtnObs.subscribe(res => {
      this.newTripBtn = res;
    });
  }

  // przekierowanie do mapy
  public navigateMap() {
    this.router.navigate(['map', 'new_trip']);
  }

  public toggle() {
    this.isOpened = !this.isOpened;
  }

  public close() {
    this.isOpened = false;
  }


  public redirect(link) {
    this.isOpened = false;
    this.router.navigate([link]);
  }

  public tooglePage(id) {
    if (this.pageActive === id) {
      this.apiService.pageObs.next(null);
    } else {
      this.apiService.pageObs.next(id);
    }
  }
}
