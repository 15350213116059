import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router, RoutesRecognized, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import {Subscription, Observable, Subject} from 'rxjs';
import {ToolSetService} from './map/services/tool-set.service';
import {ApiService} from './shared/api.service';
import {debounceTime} from 'rxjs/operators';
import {ConnectionService} from 'ng-connection-service';
import {ToastService} from './shared/toast.service';
import {Toast} from './map/models/models.map';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  public menuVisible = false;
  public menuOpened = false;
  public newTripBtn = true;
  public isOnline: boolean = true;

  public infoToast: Toast = null;

  public loader = false;

  private clicks = new Subject();
  private subscription: Subscription;

  private openToastSubscription: Subscription;

  constructor(
    private route: Router,
    public toolSetService: ToolSetService,
    public apiService: ApiService,
    private connectionService: ConnectionService,
    public toast: ToastService
  ) {
    this.route.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        const routeData = event.state.root.firstChild;
        this.menuVisible = routeData.data['barVisible'];
      }
      this.navigationInterceptor(event);
    });

    this.subscription = this.clicks.pipe(
      debounceTime(500)
    ).subscribe(e => {
      window.location.reload();
      this.loader = false;
    });

    this.connectionService.monitor().subscribe(res => {
      this.isOnline = res;
    });

    /** uruchomienie kompomentu toast */
    this.openToastSubscription = this.toast.subjectToast.subscribe((res: Toast) => {
      this.infoToast = res;
    });


  }

  ngOnInit(): void {
    this.apiService.showLoaderObs.subscribe(res => {
      this.loader = res;
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.loader = false;
    this.openToastSubscription.unsubscribe();
  }

  touchmove(event) {
    this.loader = true;
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

  navigationInterceptor(event): void {
    if (event instanceof NavigationStart) {
      this.apiService.showLoaderObs.next(true);
    }
    if (event instanceof NavigationEnd) {
      this.apiService.showLoaderObs.next(false);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.apiService.showLoaderObs.next(false);
    }
    if (event instanceof NavigationError) {
      this.apiService.showLoaderObs.next(false);
    }
  }
}
