import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, OnDestroy} from '@angular/core';
import {ToastService} from '../toast.service';
import {Toast} from '../../map/models/models.map';
import {Subscription} from 'rxjs';
import {ToastComponent} from '../toast/toast.component';

@Component({
  selector: 'app-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss']
})
export class DynamicComponent implements OnInit, OnDestroy {

  private openToastSubscription: Subscription;

  constructor(
    private resolver: ComponentFactoryResolver,
    public toast: ToastService,
  ) {

    this.openToastSubscription = this.toast.subjectToast.subscribe((res: Toast) => {
      this.createToast(res);
    });

  }

  @ViewChild('toastContainer', {read: ViewContainerRef}) toastContainer;

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.openToastSubscription.unsubscribe();
  }

  private createToast(content) {
    const factory = this.resolver.resolveComponentFactory(ToastComponent);
    const componentRef = this.toastContainer.createComponent(factory);
    componentRef.instance['content'] = content;
    const index = this.toastContainer.indexOf(componentRef.hostView);
    componentRef.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.toastContainer.remove(index);
    }, content.time);
  }

}
