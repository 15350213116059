import {Injectable} from '@angular/core';
import {TripService} from './trip.service';
import {ToolSetService} from './tool-set.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectServiceService {

  constructor(
    public tripService: TripService,
    public toolSetService: ToolSetService
  ) {
  }

  /**
   * tłumaczy dni tygonia
   */
  public translate(day: string): string {

    switch (day) {
      case 'Monday':
        return 'poniedziałek';

      case 'Tuesday':
        return 'wtorek';

      case 'Wednesday':
        return 'środa';

      case 'Thursday':
        return 'czwartek';

      case 'Friday':
        return 'piątek';

      case 'Saturday':
        return 'sobota';

      case 'Sunday':
        return 'niedziela';
    }
  }

  /**
   * funkcja usuwa oznaczenie url z array
   */
  public prepareUrl(gallery: any): Array<string> {
    const arrayReturn: Array<string> = [];

    if (!!gallery) {
      gallery.forEach(element => {
        arrayReturn.push(element['url']);
      });

    }

    return arrayReturn;
  }

  /**
   * funkcja wpisuje do tablicy datę
   */
  public setDaysInArray(numberOfIteration: number): Array<any> {
    let today = (!!this.tripService.getStartTrip()) ? this.tripService.getStartTrip() : this.toolSetService.getTomorrow();
    today = today.replace(/\./g, '/');
    // var d = Date.parse("11/30/2011");
    const todayArray = today.split('/');
    const todayDate = new Date(parseInt(todayArray[2], 10), parseInt(todayArray[1], 10) - 1, parseInt(todayArray[0], 10));
    const arrayOfDays = [];
    for (let i = 0; i <= numberOfIteration - 1; i++) {
      const formatData = new Date();
      formatData.setDate(todayDate.getDate() + i);
      arrayOfDays.push(formatData);
    }

    return arrayOfDays;

  }


  /** zwacanie kolejnego dnia w tablicy */
  public getDay(arrayOfDays) {

    const length = arrayOfDays.length;
    const endTrip = new Date(arrayOfDays[length - 1]);
    const formatData = new Date();
    formatData.setDate(endTrip.getDate() + 1);

    return formatData;
  }

}
