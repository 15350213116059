import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../shared/api.service";
import { DomSanitizer } from '@angular/platform-browser';
import {DeviceService} from "../shared/device.service";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  @ViewChild('about') about: ElementRef;

  @Input('menuVisible') menuVisible: Boolean;

  public content;
  public title ='';

  public aboutStatus = false;

  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    public deviceService: DeviceService
  ) { }

  ngOnInit() {

    this.apiService.pageObs.subscribe(res => {
      if (res === '1') {
        if (this.title === '') {
          this.apiService.getPage('478').subscribe(response => {
            if (response.status['success']) {
              this.content = this.sanitizer.bypassSecurityTrustHtml(response['data']['page']['content']);
              this.title = response['data']['page']['name'];
              this.aboutStatus = true;
            }
          });
        } else {
          this.aboutStatus = true;
        }

      } else {
        this.aboutStatus = false;
      }
    });
  }

  public close() {
    this.apiService.pageObs.next(null);
  }


}
