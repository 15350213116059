import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ApiService} from '../shared/api.service';
import {TripService} from '../map/services/trip.service';
import {DeviceService} from '../shared/device.service';
import {LocalStorageService} from '../shared/local-storage.service';

declare var window;

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {


  public trips: Array<any>;
  public iconsBaseUrl = 'assets/images/';
  public platform;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public tripService: TripService,
    public deviceService: DeviceService,
    public localStorage: LocalStorageService
  ) {
  }

  ngOnInit() {


    this.activatedRoute.data.subscribe((data: Array<any>) => {
      this.trips = data['trips']['trips'];
    });

    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.platform = this.deviceService.getPlatform();
    this.tripService.newTripBtnObs.next(true);
  }

  public redirectToStart() {
    this.router.navigate(['start']);
  }

  // przekierowanie do mapy
  public navigateMap() {
    this.router.navigate(['map', 'new_trip']);
  }

  // przekierowanie do trip-u
  public tripDetails(id) {
    this.router.navigate(['trip', id]);
  }

  /**
   * zwraca tytul dla poszczególnej wycieczki
   */
  public getTitle(name: string): string {
    return name.trim().split(' ')[0];
  }

  /**
   * zwraca tytul dla poszczególnej wycieczki
   */
  public getSubTitle(name: string): any {
    const spaceIndex = name.trim().indexOf(' ');
    if (spaceIndex !== -1) {
      return name.trim().substr(spaceIndex);
    } else {
      return '';
    }
  }

  public getShortDesc(text: string, words: number): string {
    if (text === null) {
      return '';
    } else if (text.trim().split(' ').length <= words) {
      return text.trim();
    } else if (text.trim().split(' ').length > words) {
      const resultArray = text.trim().split(' ').slice(0, words);
      return resultArray.join(' ') + '...';
    }
  }

}
