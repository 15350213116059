import {Component, OnInit, Input, HostListener} from '@angular/core';
import {Router} from '@angular/router';
import {MapService} from '../map/services/map.service';
import {THIS_EXPR} from '@angular/compiler/src/output/output_ast';
import {ApiService} from '../shared/api.service';
import {DeviceService} from '../shared/device.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input('menuVisible') menuVisible: Boolean;

  public tripDays = null;
  public toggleMenuValue = {state: true, action: 'Map'};

  constructor(
    private router: Router,
    private mapService: MapService,
    public deviceService: DeviceService,
    public apiService: ApiService
  ) {
  }

  ngOnInit() {
    this.mapService.tripDaysObserver.subscribe(res => {
      this.tripDays = res;
    });

    this.mapService.toggleMenuItemObs.subscribe(res => {
      this.toggleMenuValue = res;
    });
  }

  /** przekirowuje do strony startowej */
  public redirectToStart() {
    this.router.navigate(['start']);
  }

  /** zamyka okna wyszukiwarki, informacji o obiekcie  */
  public closeAll() {
    const close = {
      state: 'close',
    };
    this.pageClose();
    this.mapService.subject.next('closeInfoToolbar');
    this.mapService.toolBarSubject.next(close);
  }

  public showToolBar(action: string): void {
    const open = {
      state: 'open',
      action: action
    };
    this.mapService.toolBarSubject.next(open);
    this.pageClose();
  }

  public toggleMenuItem(action: string): void {
    if (action === this.toggleMenuValue['action']) {
      this.mapService.toggleMenuItemObs.next({state: !this.toggleMenuValue['state'], action: action});
    } else {
      this.mapService.toggleMenuItemObs.next({state: true, action: action});
    }

    this.pageClose();
  }

  public infoDay(action = 'infoDay'): void {
    const open = {
      state: 'open',
      action: action
    };
    this.mapService.infoDay.next(open);
  }

  public infoTrip(action = 'infoTrip'): void {
    const open = {
      state: 'open',
      action: action
    };
    this.mapService.infoTrip.next(open);
  }

  public pageClose() {
    this.apiService.pageObs.next(null);
  }
}
