import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {DynamicComponent} from './dynamic/dynamic.component';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,

  ],
  declarations: []
})
export class SharedModule {
}
