import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { MapService } from '../services/map.service';

@Component({
  selector: 'app-map-pin',
  templateUrl: './map-pin.component.html',
  styleUrls: ['./map-pin.component.scss']
})
export class MapPinComponent implements OnInit {


  @Input('monument') monument;
  @Input('selectedPin') selectedPin;

  @Output('clickPin') clickPin = new EventEmitter<any>();

  constructor(public mapService: MapService) { }

  @HostListener('onmouseover') onMouseOver() {}

  ngOnInit() {}

  public ToFloat(input: string) {
    return this.mapService.ToFloat(input);
  }

  public emitClick(pin) {
    this.clickPin.emit(pin);
  }

}
