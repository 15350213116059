import {Injectable} from '@angular/core';
import {formatDate} from '@angular/common';
import {Subject} from 'rxjs';
import {DeviceService} from 'src/app/shared/device.service';
import {ToastMaker} from '../models/models.map';
import {ToastService} from '../../shared/toast.service';

declare var device: any;

declare var cordova: any;

declare global {
  interface Window {
    resolveLocalFileSystemURL: any;
  }

  interface Window {
    LocalFileSystem: any;
  }

  interface Entry {
    getDirectory: any;
  }

  interface FileSystemFileEntry {
    createWriter: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ToolSetService {

  constructor(
    public deviceService: DeviceService,
    public toast: ToastService
  ) {
  }

  public timeSubject = new Subject<any>();

  public updateDragList = new Subject<any>();


  public printSubject = new Subject<any>();

  private currentDate;

  public exportTripBrowser(response): void {
    const url = response['data']['file']['content'];
    const currentDevice = this.deviceService.getPlatform();

    let filename = url.substring(url.lastIndexOf('file=') + 5);

    let link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (currentDevice === 'browser') {
      link.setAttribute('download', filename);
    }

    link.setAttribute('href', url);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (currentDevice === 'browser') {
      this.toast.subjectToast.next(ToastMaker.create({message: 'Dane zostały zapisane w pliku ' + filename, time: 4000, type: 'success'}));
    }
  }


  public setCurrentDate(inputData) {
    this.currentDate = inputData;
  }

  public getCurrentDate() {
    return this.currentDate;
  }

  public getToday(flag = true) {
    const today = new Date();
    if (flag) {
      this.setCurrentDate(today);
    }
    return formatDate(new Date(), 'dd.MM.yyyy', 'en');
  }

  public getTomorrow(flag = true) {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (flag) {
      this.setCurrentDate(tomorrow);
    }
    return formatDate(tomorrow, 'dd.MM.yyyy', 'en');
  }


  public getNextDay(index: number) {

    if (index === 0) {
      return this.getCurrentDate();
    }
    const endTrip = this.getCurrentDate();
    const nextDay = new Date();
    nextDay.setDate(endTrip.getDate() + index);

    return nextDay; // formatDate(nextDay, 'dd.MM.yyyy', 'en');

  }
}
