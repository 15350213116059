import {Component, OnInit, ViewChild, ElementRef, Renderer2} from '@angular/core';
import {LocaleService} from '../../share/locale.service';
import {Catergory, Monument, ToastMaker, Trip} from '../models/models.map';
import {Router, ActivatedRoute} from '@angular/router';
import {MapService} from '../services/map.service';
import {Renderer} from 'leaflet';
import {SaveFormComponent} from '../save-form/save-form.component';
import {TripService} from '../services/trip.service';
import {ApiService} from 'src/app/shared/api.service';
import {ToolSetService} from '../services/tool-set.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {LocalStorageService} from '../../shared/local-storage.service';
import {ShareButtons} from '@ngx-share/core';
import {Subscription} from 'rxjs';
import {ToastService} from '../../shared/toast.service';
import {DeviceService} from '../../shared/device.service';

@Component({
  selector: 'app-object-form',
  templateUrl: './object-form.component.html',
  styleUrls: ['./object-form.component.scss']
})
export class ObjectFormComponent extends SaveFormComponent {

  @ViewChild('categories') categories: ElementRef;
  @ViewChild('navLeft') navLeft;
  @ViewChild('objectPassword') objectPasswordView;

  public toggleFlag = false;
  public actionName = 'ObjectForm';
  public monument: Monument = null;
  public monumentForm: FormGroup;
  public categoriesArray: Array<Catergory> = [];
  public categoriesSelected = [];
  public filesArray: Array<File> = [];
  public subscription = new Subscription();

  public phonePattern = '^[0-9]{8,13}';

  public monumentFormErrors = {
    name: '',
    phone: '',
    www: '',
    www_wiki: '',
    www_reservation: '',
    www_fb: '',
    www_twitter: '',
    www_yt: '',
    www_panorama: '',
    time_tour: '',
    user_name: '',
    user_phone: '',
    user_email: '',
    regulamin: '',
  };

  public monumentValidationMessages = {
    name: {
      required: 'Nazwa obiektu jest wymagana',
      minlength: 'Nazwa obiektu musi składać się z min. 3 znaków'
    },
    phone: {
      pattern: 'Nie prawidłowy numer telefonu'
    },
    www: {
      pattern: 'Nie prawidłowy adres WWWW'
    },
    www_reservation: {
      pattern: 'Nie prawidłowy adres WWWW'
    },
    www_wiki: {
      pattern: 'Nie prawidłowy adres WWWW'
    },
    www_fb: {
      pattern: 'Nie prawidłowy adres WWWW'
    },
    www_twitter: {
      pattern: 'Nie prawidłowy adres WWWW'
    },
    www_yt: {
      pattern: 'Nie prawidłowy adres WWWW'
    },
    www_panorama: {
      pattern: 'Nie prawidłowy adres WWWW'
    },
    time_tour: {
      pattern: 'Wprowadź liczbę całkowitę (tzn. liczbę minut)'
    },
    user_name: {
      required: 'Imię / nick jest wymagane',
      minlength: 'Imię / nick musi składać się z min. 3 znaków'
    },
    user_phone: {
      pattern: 'Nie prawidłowy numer telefonu'
    },
    user_email: {
      required: 'E-mail jest wymagany',
      email: 'Podaj prawidłowy adres e-mail'
    },
    regulamin: {
      required: 'Wymagana jest akceptacja polityki prywatności'
    }
  };

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  constructor(
    private localStorage: LocaleService,
    public router: Router,
    public mapService: MapService,
    public tripService: TripService,
    public renderer: Renderer2,
    public apiService: ApiService,
    public toolSetService: ToolSetService,
    public formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public share: ShareButtons,
    public deviceService: DeviceService,
    public toast: ToastService
  ) {
    super(router, mapService, tripService, renderer, apiService, toolSetService, formBuilder, activeRoute, localStorageService, share, deviceService, toast);
  }

  public ngOnInit() {
    this.subscription.add(
      this.mapService.toggleMenuItemObs.subscribe(info => {
        if (info.state && info.action === this.actionName) {
          this.open();
        } else {
          if (this.toggleFlag) {
            this.hide();
          }
        }
      })
    );

    this.categoriesArray = this.localStorage.getItem('Guide', 'Categories');

    /** Ustawienie checkboxów kategorii na false*/
    for (let i = 0; i < this.categoriesArray.length; i++) {
      this.categoriesSelected[i] = false;
    }

    /** Pola formularza*/
    if (this.deviceService.getPlatform() === 'browser') {
      /** Desktop */
      this.monumentForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        categories: this.formBuilder.array(this.categoriesSelected),
        street: [''],
        street_number: [''],
        local_number: [''],
        city: [''],
        original_city: [''],
        zip_code: [''],
        post_office: [''],
        district: [''],
        accommodation: [''],
        phone: [''],
        email: [''],
        www: [''],
        www_reservation: [''],
        www_wiki: [''],
        www_fb: [''],
        www_twitter: [''],
        www_yt: [''],
        www_panorama: [''],
        time_tour: [''],
        description: [''],
        openHours: [''],
        coordinates: this.formBuilder.group({
          lat: [''],
          lng: ['']
        }),
        user_name: ['', [Validators.required, Validators.minLength(3)]],
        user_phone: [''],
        user_email: ['', [Validators.required, Validators.email]],
        regulamin: ['', [Validators.requiredTrue]],
        recaptchaMonument: ['', [Validators.required]]
      });
    } else {
      /** Mobile */
      this.monumentForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.minLength(3)]],
        categories: this.formBuilder.array(this.categoriesSelected),
        street: [''],
        street_number: [''],
        local_number: [''],
        city: [''],
        original_city: [''],
        zip_code: [''],
        post_office: [''],
        district: [''],
        accommodation: [''],
        phone: [''],
        email: [''],
        www: [''],
        www_reservation: [''],
        www_wiki: [''],
        www_fb: [''],
        www_twitter: [''],
        www_yt: [''],
        www_panorama: [''],
        time_tour: [''],
        description: [''],
        openHours: [''],
        coordinates: this.formBuilder.group({
          lat: [''],
          lng: ['']
        }),
        user_name: ['', [Validators.required, Validators.minLength(3)]],
        user_phone: [''],
        user_email: ['', [Validators.required, Validators.email]],
        regulamin: ['', [Validators.requiredTrue]]
      });
    }

    this.monumentForm.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
      this.onMonumentValueChanged();
    });

    this.onMonumentValueChanged();

  }

  public hide(): void {
    if (this.toggleFlag === true) {
      this.toggleFlag = false;
      this.renderer.addClass(this.navLeft.nativeElement, 'closed');
    }

  }

  public open() {
    this.toggleFlag = true;
    this.renderer.removeClass(this.navLeft.nativeElement, 'closed');
  }

  public rules(): void {
    this.apiService.pageObs.next('3');
  }

  public writeData(monument: Monument): void {
    this.monumentForm.reset();
    this.monument = monument;
    for (let field in monument) {

      if (field !== 'coordinates' && field !== 'categories' && field !== 'accomodation' && field !== 'time_tour') {
        if ((typeof this.monumentForm.controls[field] !== 'undefined') && (typeof monument[field] !== 'undefined')) {
          this.monumentForm.controls[field].patchValue(monument[field]);
        }
      }

      if (field === 'coordinates') {
        this.monumentForm.patchValue({
          coordinates: {
            lat: monument['coordinates'][0].lat,
            lng: monument['coordinates'][0].lng
          }
        });
      }

      if (field === 'categories') {
        for (let i = 0; i < this.categoriesArray.length; i++) {
          for (let category of monument['categories']) {
            if (this.categoriesArray[i]['name'] === category['name']) {
              this.categoriesSelected[i] = true;
              break;
            } else {
              this.categoriesSelected[i] = false;
            }

          }
        }
        this.monumentForm.patchValue({
          categories: this.categoriesSelected
        });
      }

      if (field === 'accommodation') {
        this.monumentForm.controls[field].patchValue(monument[field] === 1);
      }

      if (field === 'time_tour') {
        this.monumentForm.controls[field].patchValue(this.getTimeToInt(monument[field]));
      }
    }
  }

  public getTimeToInt(dataTime) {
    if (dataTime === null) {
      return '';
    }

    let time = dataTime.split(':');
    for (let i = 0; i < 2; i++) {
      while (time[i].charAt(0) === '0') {
        time[i] = time[i].substr(1);
      }
    }
    return time[0] * 60 + time[1];
  }

  public getIntToTime(value) {
    if (value === null || value === '') {
      return '00:00:00';
    } else {
      let hours = Math.floor(value / 60);
      let minutes = value - (hours * 60);

      let h = '';
      let m = '';

      if (hours < 10) {
        h = '0' + hours;
      } else {
        h = hours.toString();
      }
      if (minutes < 10) {
        m = '0' + minutes;
      } else {
        m = minutes.toString();
      }

      return h + ':' + m + ':00';
    }
  }

  public showSelectedFiles(fileInput: any) {
    this.filesArray = fileInput.target.files;
  }

  public onMonumentValueChanged(): void {
    const form = this.monumentForm;

    for (let field in this.monumentFormErrors) {
      this.monumentFormErrors[field] = '';
      let control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const validationMessages = this.monumentValidationMessages[field];
        for (const key in control.errors) {
          this.monumentFormErrors[field] += validationMessages[key] + ' ';
        }
      }
    }
  }

  public sendChangedObject() {
    if (this.objectPasswordView.nativeElement.value === '') {
      this.apiService.showLoader();
      const catValue = this.monumentForm.value['categories'];
      let categories = [];

      /** wypełnienie tablicy kategoriami */
      for (let i = 0; i < catValue.length; i++) {
        if (catValue[i]) {
          categories.push({'name': this.categoriesArray[i]['name']});
        }
      }

      let result = this.monumentForm.value;
      result['categories'] = categories;
      result['time_tour'] = this.getIntToTime(result['time_tour']);
      delete result['file'];
      delete result['recaptchaMonument'];

      this.apiService.sendChangedObject(this.monument['id_objects'], result, this.filesArray).subscribe(res => {
        if (res.status['success']) {
          this.monumentForm.reset();
          this.hide();
          this.toast.subjectToast.next(ToastMaker.create({
            message: 'Twoja propozycja zmian została wysłana, dziękujemy. Przeanalizujemy zaproponowane modyfikacje.',
            time: 4000,
            type: 'success'
          }));
          this.apiService.hideLoader();
        } else {
          this.toast.subjectToast.next(ToastMaker.create({
            message: 'Nie udało się wysłać propozycji zmian. Skontaktuj się z nami, aby wyjaśnić sytuację.',
            time: 4000,
            type: 'error'
          }));
          this.apiService.hideLoader();
        }
      });
    }
  }

  public hideForm() {
    this.hide();
    this.monumentForm.reset();
  }
}
