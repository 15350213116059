import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MapsModule} from './map/map.module';
import {StartModule} from './start/start.module';
import {MenuComponent} from './menu/menu.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InterceptorService} from './shared/interceptor.service';
import {SharedModule} from './shared/shared.module';
import {MenuLeftComponent} from './menu-left/menu-left.component';
import {MenuItemComponent} from './menu-left/menu-item/menu-item.component';
import {TripComponent} from './trip/trip.component';
import {RulesComponent} from './rules/rules.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {RecaptchaModule} from 'ng-recaptcha';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {SwiperModule} from 'ngx-useful-swiper';
import {CookieService} from 'ngx-cookie-service';
import {CookieComponent} from './cookie/cookie.component';
import {NoConnectionComponent} from './no-connection/no-connection.component';
import {ToastComponent} from './shared/toast/toast.component';
import {ContactComponent} from './contact/contact.component';
import {BibliographyComponent} from './bibliography/bibliography.component';
import {DynamicComponent} from './shared/dynamic/dynamic.component';
import {AboutComponent} from './about/about.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    MenuLeftComponent,
    MenuItemComponent,
    TripComponent,
    RulesComponent,
    CookieComponent,
    NoConnectionComponent,
    ToastComponent,
    ContactComponent,
    BibliographyComponent,
    DynamicComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StartModule,
    MapsModule,
    SharedModule,
    DragDropModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SwiperModule
  ],
  entryComponents: [
    ToastComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
