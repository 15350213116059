import {Component, OnInit, Input, ViewChild, ElementRef, Renderer2, Output, EventEmitter, OnDestroy} from '@angular/core';
import {DistanceDuration, Monument, MonumentShort, MonumentShortMaker, ToastMaker} from '../models/models.map';
import {ObjectServiceService} from '../services/object-service.service';
import {ApiService} from '../../shared/api.service';
import {MapService} from '../services/map.service';
import {TripService} from '../services/trip.service';
import {DirectionService} from '../services/direction.service';
import {ObjectFormComponent} from '../object-form/object-form.component';
import {Subscription} from 'rxjs';
import {DeviceService} from '../../shared/device.service';
import {ToastService} from '../../shared/toast.service';

@Component({
  selector: 'app-object-menu',
  templateUrl: './object-menu.component.html',
  styleUrls: ['./object-menu.component.scss']
})
export class ObjectMenuComponent implements OnInit, OnDestroy {

  public monument: Monument = null;
  public trip: any;
  public trip_days = [];

  public toggleFlag = false;

  public idDaysMonuments: Array<number> = [];

  public arrayOfDays: Array<any> = [];

  /** lista adresow do mapy */
  public imageUrlArray: Array<string> = [];

  /** flaga na ulubionych **/

  public ulubioneFlag = false;

  /** Ulubione obiekty */
  public favoriteObjects = [];

  public timeOfSightseeing = '01:00:00';
  /** Domyślny czas zwiedzania*/

  public dayArray: Array<any> = [];

  public subscription = new Subscription();

  private collapseArray: Array<any> = [];

  public distanceResponse: any = null;

  public domFlag = false;

  /** referecja do lewego okienka */
  @ViewChild('navLeft') navLeft: ElementRef;
  @ViewChild('items') items: ElementRef;
  public subscItems = new Subscription();

  @Input('appToolbar') appToolbar;
  @Input('dayDetail') dayDetail;
  @Input('tripInfo') tripInfo;
  @Input('objectForm') objectForm: ObjectFormComponent;

  @Output('removeAddObj') removeAddObj = new EventEmitter<any>();

  @Output('centerMapEmiter') centerMapEmiter = new EventEmitter<any>();

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscItems.unsubscribe();
  }

  constructor(
    private renderer: Renderer2,
    public apiService: ApiService,
    public objectServiceService: ObjectServiceService,
    public mapService: MapService,
    public tripService: TripService,
    public directionService: DirectionService,
    public deviceService: DeviceService,
    public toast: ToastService
  ) {
  }

  ngOnInit() {
    this.subscription.add(
      this.mapService.toggleMenuItemObs.subscribe(info => {
        if (info.state && info.action === 'infoMonument') {
          this.open();
        } else {
          if (info.action !== 'ObjectForm') {
            this.hide();
          }
        }
      })
    );

    this.collapseArray = [
      ['gallery-btn', 'gallery-content', true],
      ['distance-btn', 'distance-content', false],
      ['address-btn', 'address-content', false],
      ['target-btn', 'target-content', false],
      ['contact-btn', 'contact-content', false],
      ['links-btn', 'links-content', false],
      ['desc-btn', 'desc-content', false],
      ['categories-btn', 'categories-content', false],
      ['hours-btn', 'hours-content', false]
    ];

    this.directionService.distanceToTarget.subscribe(res => {
      this.distanceResponse = res;
    });
  }


  onDomChange($event): void {
    const lastEl = $event[$event.length - 2].firstElementChild;
    if (this.domFlag === true) {
      this.renderer.addClass(lastEl, 'active');
      this.domFlag = false;
    }


  }

  /**
   * wypisuje dane z powyższego komponentu
   */
  public writeData(monument: any): void {
    this.idDaysMonuments = [];
    this.monument = monument;
    this.trip_days = this.tripService.getDays();
    this.ulubioneFlag = this.monument['is_favorite'];
    this.distanceResponse = null;

    if (Array.isArray(this.trip_days) && this.trip_days.length > 0) {

      for (let i = 0; i < this.trip_days.length; i++) {

        const arrayMonument = this.trip_days[i].objects;
        arrayMonument.forEach(element => {
          if (this.monument.id_objects === element.id_objects) {
            this.idDaysMonuments.push(i);
          }
        });

      }
      this.arrayOfDays = this.objectServiceService.setDaysInArray(this.trip_days.length);
    } else {
      this.arrayOfDays = this.objectServiceService.setDaysInArray(1);
    }

    this.imageUrlArray = this.objectServiceService.prepareUrl(this.monument.gallery);
  }

  /**
   * funkcja ukrywa pasek boczny
   */
  public hide(): void {
    if (this.toggleFlag === true) {
      this.toggleFlag = false;
      this.renderer.addClass(this.navLeft.nativeElement, 'closed');
    }
  }

  /**
   * funkcja pokazuje pasek boczny
   */
  public open(): void {
    this.toggleFlag = true;
    this.renderer.removeClass(this.navLeft.nativeElement, 'closed');
    this.appToolbar.hide();
    this.dayDetail.hide();
    this.tripInfo.hide();

    // przeliczanie wysokośći collapsów
    const collapseArray = this.collapseArray;
    setTimeout(function () {
      for (const key in collapseArray) {
        const $content = document.getElementById(collapseArray[key][1]);
        if ($content !== null && $content.classList.contains('show')) {
          $content.style.maxHeight = $content.scrollHeight + 'px';
        }
      }
    }, 500);
  }

  public getCategoryHtml(data) {
    let html = '';
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          html += '<ul>';
        }
        if (data[i].children.length > 0) {
          html += '<li><img src="' + data[i].icon + '" alt="' + data[i].name + '"/><span>' + data[i].name + '</span>' +
            '' + this.getCategoryHtml(data[i].children) + '</li>';
        } else {
          html += '<li><img src="' + data[i].icon + '" alt="' + data[i].name + '"/><span>' + data[i].name + '</span></li>';
        }

        if (i === data.length - 1) {
          html += '</ul>';
        }
      }
    }

    return html;
  }

  /**dodaje lub odejmuje dzien */

  public removeAdd(element, index: number) {


    const elementRef = new ElementRef(element);
    const pclassAttr = elementRef.nativeElement.className;
    let tripArray = [];
    tripArray = this.tripService.getDayMonuments(index);

    if (pclassAttr.indexOf('active') !== -1) {

      /** usuniecie objektu do dnia */
      this.renderer.removeClass(elementRef.nativeElement, 'active');
      const indexArray = tripArray.findIndex(x => x.id_objects === this.monument.id_objects);
      tripArray = tripArray.splice(indexArray, 1);

      if (this.monument.accommodation === 1 && this.tripService.getLengthDays() - 1 > index) {
        const nextTripArray = this.tripService.getDayMonuments(index + 1);
        if (nextTripArray[0].id_objects === this.monument.id_objects) {
          nextTripArray.shift();
        }
      }
    } else {
      if (Array.isArray(tripArray) && tripArray.length < 25) {

        const monumentShort = MonumentShortMaker.create(this.monument);
        /** sprawdzenie czy ostatni obiekt jest noclegiem */
        if (index === 0 && tripArray.length === 1 && tripArray[0].accommodation === 1) {
          tripArray.unshift(monumentShort);
          this.renderer.addClass(elementRef.nativeElement, 'active');
        } else if (this.tripService.checkIfSlipingLast(index) === false) {

          tripArray.push(monumentShort);
          this.tripService.setDayMonuments(tripArray, index);

          if (this.monument.accommodation === 1 && this.tripService.getLengthDays() - 1 > index) {
            const nextTripArray = this.tripService.getDayMonuments(index + 1);
            if (nextTripArray.length > 0 && nextTripArray[0].accommodation === 1) {
              nextTripArray[0] = this.monument;
            } else {
              nextTripArray.unshift(this.monument);
            }
            this.renderer.addClass(elementRef.nativeElement.parentElement.parentElement.children[index + 1].children[0],
              'active');
          }
          this.renderer.addClass(elementRef.nativeElement, 'active');

          /** jesli jest noclegiem ostatni obiekt  */
        } else if (this.monument.accommodation !== 1 && tripArray.length > 1) {

          tripArray = tripArray.splice(tripArray.length - 1, 0, monumentShort);
          this.renderer.addClass(elementRef.nativeElement, 'active');

        } else if (this.monument.accommodation !== 1) {
          tripArray.push(monumentShort);
          this.renderer.addClass(elementRef.nativeElement, 'active');
        } else {
          this.toast.subjectToast
            .next(ToastMaker.create({message: 'W danym dniu znajduje się nocleg, na końcu', time: 4000, type: 'warning'}));
        }

      } else {
        this.toast.subjectToast
          .next(ToastMaker.create({message: 'Nie można dodać więcej niż 25 obiektów', time: 4000, type: 'warning'}));
      }
    }
    // renderowanie trasy
    this.directionService.directionSubjectWithOutHideObj.next(index);
  }

  /** dodawanie dnia w tablicy wyświtlen */
  public addDay() {
    // childElementCount
    const length = this.tripService.getLengthDays();
    if (length < 9) {
      // render dla DOM
      this.arrayOfDays.push(this.objectServiceService.getDay(this.arrayOfDays));
      // dodanie do JSON
      this.tripService.addNewDay();
      this.mapService.tripDaysObserver.next(this.tripService.getLengthDays());

      if (this.tripService.checkIfSlipingLast(this.tripService.getLengthDays() - 2)
        && this.tripService.getDayMonuments(this.tripService.getLengthDays() - 2).length > 1) {
        const monumentShort = MonumentShortMaker.create(this.monument);
        let tripArray = [];
        tripArray = this.tripService.getDayMonuments(this.tripService.getLengthDays() - 1);
        if (Array.isArray(tripArray)) {
          tripArray.push(monumentShort);
          this.domFlag = true;

        }
      }
    } else {
      this.toast.subjectToast.next(ToastMaker.create({
        message: 'Nie można dodać więcej niż 9 dni wycieczki.',
        time: 4000,
        type: 'warning'
      }));
    }
  }

  public centerMap(cordinates) {
    this.centerMapEmiter.emit(cordinates);
  }

  public toggleCategory(btn: string, content: string) {
    const $btn = document.getElementById(btn);
    const $content = document.getElementById(content);
    const hasClass = $content.classList.contains('show');

    if (hasClass) {
      this.renderer.removeClass($btn, 'show');
      this.renderer.removeClass($content, 'show');
      $content.style.maxHeight = null;
    } else {
      this.renderer.addClass($btn, 'show');
      this.renderer.addClass($content, 'show');
      $content.style.maxHeight = $content.scrollHeight + 'px';
    }
  }

  public addToUlubione(id) {
    if (this.ulubioneFlag === true) {
      this.ulubioneFlag = false;
      this.apiService.removeFavorite(id).subscribe();
    } else {
      this.ulubioneFlag = true;
      this.apiService.addFavorite(id).subscribe();
    }
  }


  public openFormObj() {
    this.objectForm.writeData(this.monument);
    this.mapService.toggleMenuItemObs.next({state: true, action: 'ObjectForm'});
  }

  public getDistanceToTarget() {
    if (navigator.geolocation && this.deviceService.getPlatform() === 'browser') {
      this.apiService.showLoader();
      navigator.geolocation.getCurrentPosition((cords) => {
        this.showDistance(cords);
      }, (error) => {
        this.showError(error);
      });
    }

    if (this.deviceService.getPlatform() !== 'browser') {
      if (window.navigator && window.navigator.geolocation) {
        this.apiService.showLoader();
        window.navigator.geolocation.getCurrentPosition((cords) => {
          this.showDistance(cords);
        }, (error) => {
          this.showError(error);
        });
      }
    }
  }

  public showDistance(position) {
    this.apiService.hideLoader();
    const distance = this.getFormattedDistance(6371 * Math.acos(Math.cos(Math.PI / 180 * (position.coords.latitude)) * Math.cos(Math.PI / 180 * (this.monument.coordinates[0].lat)) * Math.cos(Math.PI / 180 * (this.monument.coordinates[0].lng) - (Math.PI / 180 * (position.coords.longitude))) + Math.sin(Math.PI / 180 * (position.coords.latitude)) * Math.sin(Math.PI / 180 * (this.monument.coordinates[0].lat))) * 1000);
    this.directionService.distanceToTarget.next(distance);
  }

  public showError(error) {
    let tekst = 'error';
    switch (error.code) {
      case error.PERMISSION_DENIED:
        tekst = 'Nie wyraziłeś zgody na pobranie lokalizacji.';
        break;
      case error.POSITION_UNAVAILABLE:
        tekst = 'Nie można określić Twojej lokalizacji.';
        break;
      case error.TIMEOUT:
        tekst = 'Przekroczono czas żądania. Skontaktuj się z nami, aby wyjaśnić sytuację.';
        break;
      case error.UNKNOWN_ERROR:
        tekst = 'Wystąpił nieznany błąd. Skontaktuj się z nami, aby wyjaśnić sytuację.';
        break;
    }

    this.toast.subjectToast.next(ToastMaker.create({message: tekst, time: 4000, type: 'error'}));

    this.apiService.hideLoader();
  }

  public getFormattedTime(dataTime) {
    if (dataTime === null) {
      dataTime = this.timeOfSightseeing;
    }

    let html = '';
    let time = dataTime.split(':');
    for (let i = 0; i < 2; i++) {
      while (time[i].charAt(0) === '0') {
        time[i] = time[i].substr(1);
      }
    }

    if (time[0] !== '') {
      /** godziny */
      html += time[0] + 'h';

      /** minuty */
      if (time[1] !== '') {
        html += ' ' + time[1] + 'min.';
      }
    } else {
      if (time[1] !== '') {
        /** tylko minuty */
        html += time[1] + 'min.';
      } else {
        if (time[2] !== '') {
          html += '1min.'; // zabezpieczenia jak czas przebycia trwa kilka sekund
        } else {
          html += '1h';
        }
      }
    }

    return html;
  }

  public setRoadInfo(roadInfo) {
    let infoAboutWay = [];
    const tmpWaypoints = roadInfo.via_waypoint;
    const tmpSteps = roadInfo.steps;
    const indexOfWayPoint = [0];

    for (let i = 0; i < tmpWaypoints.length; i++) {
      indexOfWayPoint.push(tmpWaypoints[i].step_index);
    }
    indexOfWayPoint.push(tmpSteps.length);

    for (let j = 0; j < indexOfWayPoint.length - 1; j++) {
      const nextJ = j + 1;
      const tmpWay = tmpSteps.slice(indexOfWayPoint[j], indexOfWayPoint[nextJ]);
      let distance = 0;
      let duration = 0;
      for (let i = 0; i < tmpWay.length; i++) {
        distance += tmpWay[i].distance.value;
        duration += tmpWay[i].duration.value;
      }

      const tmpJson: DistanceDuration = {distance: this.getFormattedDistance(distance), duration: this.secondsToHms(duration)};
      infoAboutWay.push(tmpJson);
    }

    return infoAboutWay;
  }

  public secondsToHms(d) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = d % 60;

    return this.getFormattedTime(h + ':' + m + ':' + s);
  }

  public getFormattedDistance(distance) {
    let html = '';
    if (distance === null) {
      html += '0 km';
    } else {
      html += Math.round(distance / 10) / 100 + ' km';
    }

    return html;
  }
}
