import {Component, OnInit, ViewChild, Renderer2, ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {MapService} from '../services/map.service';
import {TripService} from '../services/trip.service';
import {ApiService} from 'src/app/shared/api.service';
import {ToolSetService} from '../services/tool-set.service';
import {DayAvatarComponent} from '../trip-info/day-avatar/day-avatar.component';
import {DirectionService} from '../services/direction.service';
import {DistanceDuration, DayOfTrip, Monument, ToastMaker} from '../models/models.map';
import {SearchService} from '../services/search.service';
import * as moment from 'moment';
import {ObjectMenuComponent} from '../object-menu/object-menu.component';
import {DeviceService} from 'src/app/shared/device.service';
import {ThrowStmt} from '@angular/compiler';
import {Subscription} from 'rxjs';
import {LocalStorageService} from 'src/app/shared/local-storage.service';
import {ToastService} from '../../shared/toast.service';

@Component({
  selector: 'app-day-details',
  templateUrl: './day-details.component.html',
  styleUrls: ['./day-details.component.scss']
})


export class DayDetailsComponent extends DayAvatarComponent implements OnInit {

  @ViewChild('navLeft') navLeft;


  @Input('monumentDesc') monumentDesc: ObjectMenuComponent;

  @Output('selectedPin') selectedPin = new EventEmitter<any>();
  @Output('cordsFromSearch') cordsFromSearch = new EventEmitter<any>();

  public iconsBaseUrl = 'assets/images/';
  public toggleFlag = false;
  public day: DayOfTrip;
  public indexOfDay;
  public dayindex;
  public timeOfSightseeing = '01:00:00';
  /** Domyślny czas zwiedzania*/
  public infoAboutWay = new Array<DistanceDuration>();

  public timeBegin = null;
  public selectedTime;
  public config = {
    locale: 'pl',
    showTwentyFourHours: true
  };
  public toggleTime = false;

  public getRoadFromGoogleMaps = null;

  public arrayStart = [];
  public arrayStop = [];

  public duration = null;
  public distance = null;
  public isSavedTrip: boolean = false;
  public outOfDay: boolean = false;
  public readyToExport: boolean = false;
  private exportTrip: boolean = false;

  public lastClickedGPXorKML = null;

  public subscription = new Subscription();
  public subscriptionKLM = new Subscription();
  public subscriptionGPX = new Subscription();


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  constructor(
    public directionService: DirectionService,
    public router: Router,
    public mapService: MapService,
    public renderer: Renderer2,
    public tripService: TripService,
    public apiService: ApiService,
    public toolSetService: ToolSetService,
    public searchService: SearchService,
    public cdRef: ChangeDetectorRef,
    public deviceServise: DeviceService,
    public storage: LocalStorageService,
    public toast: ToastService
  ) {
    super(router, renderer, mapService, tripService, toolSetService, directionService, searchService, cdRef, deviceServise, storage, toast);
  }

  ngOnInit() {
    this.subscription.add(
      this.mapService.toggleMenuItemObs.subscribe(info => {
        if (info.state && info.action === 'infoDay') {
          this.open();
        } else {
          this.hide();
        }
      })
    );

    this.tripService.isSavedTripObs.subscribe(res => {
      this.isSavedTrip = res;
    });

    this.tripService.outOfDayObs.subscribe(res => {
      this.outOfDay = res;
    });

    this.tripService.exportTripObs.subscribe(res => {
      this.exportTrip = res;
    });

    this.tripService.isSavedForExportObs.subscribe(res => {
      this.readyToExport = res;

      if (this.exportTrip) {
        if (this.lastClickedGPXorKML === 'GPX') {
          const idTrip = this.tripService.getTripId();
          let subscription = new Subscription();
          if (!!idTrip) {
            subscription = this.apiService.getTripGPXUrl(idTrip, this.indexOfDay).subscribe(response => {
              this.toolSetService.exportTripBrowser(response);
              this.tripService.exportTripObs.next(false);
              this.apiService.hideLoader();
            }).add(subscription.unsubscribe());
          } else {
            this.tripService.exportTripObs.next(false);
            this.apiService.hideLoader();
          }
        } else if (this.lastClickedGPXorKML === 'KML') {
          const idTrip = this.tripService.getTripId();
          let subscription = new Subscription();
          if (!!idTrip) {
            subscription = this.apiService.getTripKMLUrl(idTrip, this.indexOfDay).subscribe(response => {
              this.toolSetService.exportTripBrowser(response);
              this.tripService.exportTripObs.next(false);
              this.apiService.hideLoader();
            }).add(subscription.unsubscribe());
          } else {
            this.tripService.exportTripObs.next(false);
            this.apiService.hideLoader();
          }
        }
      }

    });
  }

  public toggleTimeFun() {
    this.toggleTime = !this.toggleTime;
  }

  public onTimeChange(event) {
    const date = new Date(event.date);
    const hours = date.getHours();
    const minutes = (date.getMinutes().toString().length === 1) ? '0' + date.getMinutes() : date.getMinutes();
    this.tripService.setDayStart(this.dayindex, hours + ':' + minutes);
    this.timeBegin = this.getStartTime();

    this.toolSetService.timeSubject.next(this.indexOfDay);

    this.setStartWay();

  }

  /** ukrywa pasek boczny */
  public hide(): void {
    this.infoAboutWay = [];
    if (this.toggleFlag === true) {
      // this.indexOfDay = 0;
      // this.getMonuments();
      // this.directionService.directionSubject.next(this.indexOfDay);
      this.toggleFlag = false;
      this.renderer.addClass(this.navLeft.nativeElement, 'closed');
    }

    this.day = null;
    this.toggleTime = false;

    this.distance = null;
    this.duration = null;
  }

  /**
   * funkcja pokazuje pasek boczny
   */
  public open(): void {
    this.toggleFlag = true;
    this.mapService.groupMonuments.next(false);
    this.renderer.removeClass(this.navLeft.nativeElement, 'closed');

    this.getRoadFromGoogleMaps = null;
    this.indexOfDay = this.tripService.indexOfDay.getValue();
    this.dayindex = this.indexOfDay;
    this.mapService.renderFist.next({'renderFirst': false, 'lastDayRender': this.indexOfDay});


    if (this.indexOfDay !== null) {
      this.day = this.tripService.getDay(this.indexOfDay);
    }

    this.getMonuments();

    this.getRoadInfo();


    this.monumentDesc.hide();
    this.timeBegin = this.getStartTime();
    // this.tripService.saveTrip();

    if (this.monumentsArray.length === 1) {
      this.duration = this.getFormattedTime(this.monumentsArray[0].time_tour);
      this.distance = this.monumentsArray[0].distance / 1000;
    }

    if (this.storage.hasItem('Guide', this.tripService.getTripId())) {
      this.tripService.isSavedTripObs.next(true);
    }
  }

  public getRoadInfo(): void {
    const modeOfTransport = this.tripService.getDayMode(this.indexOfDay);
    const roadParam = this.directionService.prepareParam(this.monumentsArray);
    if (!!roadParam) {
      this.directionService.getRoadFromGoogleMaps(roadParam, modeOfTransport).subscribe(response => {
        // response = response.data;

        if (this.deviceServise.getPlatform() === 'browser') {
          response = response.data;
        }
        if (response.status === 'OK') {
          this.getRoadFromGoogleMaps = response['routes'][0].legs[0];
          this.setRoadInfo();
          this.setStartWay();
        } else {
          this.toast.subjectToast.next(ToastMaker.create({message: 'Nie można było obliczyć odległości.', time: 4000, type: 'error'}));
        }
      });
    }
  }

  public setRoadInfo() {
    this.infoAboutWay = [];
    const tmpWaypoints = this.getRoadFromGoogleMaps.via_waypoint;
    const tmpSteps = this.getRoadFromGoogleMaps.steps;
    const indexOfWayPoint = [0];

    for (let i = 0; i < tmpWaypoints.length; i++) {
      indexOfWayPoint.push(tmpWaypoints[i].step_index);
    }
    indexOfWayPoint.push(tmpSteps.length);

    for (let j = 0; j < indexOfWayPoint.length - 1; j++) {
      const nextJ = j + 1;
      const tmpWay = tmpSteps.slice(indexOfWayPoint[j], indexOfWayPoint[nextJ]);
      let distance = 0;
      let duration = 0;
      for (let i = 0; i < tmpWay.length; i++) {
        distance += tmpWay[i].distance.value;
        duration += tmpWay[i].duration.value;
      }

      const tmpJson: DistanceDuration = {distance: this.getFormattedDistance(distance), duration: this.secondsToHms(duration)};
      this.infoAboutWay.push(tmpJson);
    }

  }

  public secondsToHms(d) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = d % 60;

    return this.getFormattedTime(h + ':' + m + ':' + s);
  }

  public getFormattedTime(dataTime) {
    if (dataTime === null) {
      dataTime = this.timeOfSightseeing;
    }

    let html = '';
    const time = dataTime.split(':');
    for (let i = 0; i < 2; i++) {
      while (time[i].charAt(0) === '0') {
        time[i] = time[i].substr(1);
      }
    }

    if (time[0] !== '') {
      /** godziny */
      html += time[0] + 'h';

      /** minuty */
      if (time[1] !== '') {
        html += ' ' + time[1] + 'min.';
      }
    } else {
      if (time[1] !== '') {
        /** tylko minuty */
        html += time[1] + 'min.';
      } else {
        if (time[2] !== '') {
          html += '1min.'; // zabezpieczenia jak czas przebycia trwa kilka sekund
        } else {
          html += '1h';
        }
      }
    }

    return html;
  }

  public getFormattedDistance(distance) {
    let html = '';
    if (distance === null) {
      html = '0km';
    } else {
      html = Math.round(distance / 10) / 100 + ' km';
    }

    return html;
  }


  public backToTrip() {
    this.mapService.toggleMenuItemObs.next({state: true, action: 'infoTrip'});
  }

  /** pobiera format gpx */
  public getGPX(): void {
    this.apiService.showLoader();
    this.tripService.exportTripObs.next(true);
    this.tripService.saveTrip();
    this.lastClickedGPXorKML = 'GPX';

  }


  /** pobiera format kml */
  public getKML(): void {
    this.apiService.showLoader();
    this.tripService.exportTripObs.next(true);
    this.tripService.saveTrip();
    this.lastClickedGPXorKML = 'KML';
  }


  public getStartTime() {
    if (!!this.day) {
      if (this.day.time_start === null) {
        this.tripService.setDayStart(this.indexOfDay, '8:00');
        return moment('8:00', 'HH:mm');
      } else {
        return moment(this.tripService.getDayStart(this.indexOfDay), 'HH:mm');
      }

    }
    return null;
  }

  public deleteMonument(index): void {
    const tmp = this.monumentsArray;

    if (!!tmp && Array.isArray(tmp)) {
      tmp.splice(index, 1);
      this.monumentsArray = tmp;

      if (tmp.length > 1) {
        this.directionService.directionSubject.next(this.indexOfDay);
        this.getRoadInfo();
      } else if (tmp.length === 1) {
        this.duration = this.getFormattedTime(tmp[0].time_tour);
        this.distance = tmp[0].distance / 1000;
        this.directionService.directionSubject.next(this.indexOfDay);

      } else {
        this.duration = null;
        this.distance = null;
        this.directionService.directionSubject.next(this.indexOfDay);

      }
    }
  }

  /** usuniecie dni z uwzglednieniem innych parametrow */
  public deleteDay(): void {
    this.backToTrip();
    const tmpDays = this.tripService.getDays();

    if (!!tmpDays && Array.isArray(tmpDays) && tmpDays.length > 1) {

      if (tmpDays.length >= 3 && this.dayindex <= tmpDays.length - 2) {

        const objBefore = this.tripService.checkLastMonument(this.dayindex - 1);
        const objNext = this.tripService.checkFirstMonument(this.dayindex + 1);

        if ((!!objBefore && !objNext)) {
          const tmpArrayNextDay = this.tripService.getDayMonuments(this.dayindex + 1);
          tmpArrayNextDay.unshift(objBefore);

        } else if (!!objBefore && !!objNext) {
          const tmpArrayNextDay = this.tripService.getDayMonuments(this.dayindex + 1);
          tmpArrayNextDay[0] = objBefore;

        }
        // else if (!objBefore && !!objNext) {
        //   const tmpArrayNextDay = this.tripService.getDayMonuments(this.dayindex - 1);
        //   tmpArrayNextDay[tmpArrayNextDay.length - 1] = objNext;

        // }
      }
      tmpDays.splice(this.dayindex, 1);
      this.tripService.setDays(tmpDays);
      this.directionService.directionSubject.next(0);
      this.mapService.tripDaysObserver.next(this.tripService.getLengthDays());

    }
  }


  public ShowHourStart() {
    if (!!this.timeBegin) {
      const hours = (this.timeBegin.hours().toString().length === 1) ? '0' + this.timeBegin.hours() : this.timeBegin.hours();
      const minutes = (this.timeBegin.minutes().toString().length === 1) ? '0' + this.timeBegin.minutes() : this.timeBegin.minutes();
      return hours + ':' + minutes;
    } else {
      return null;
    }
  }

  /** pokazuje informacje o monumencie */
  public showInfoMonument(monument) {
    this.apiService.getMonument(monument.id_objects.toString()).subscribe(response => {
      const object = response['data']['object'];
      if (object) {
        this.monumentDesc.writeData(object);
        this.mapService.toggleMenuItemObs.next({state: true, action: 'infoMonument'});
        this.selectedPin.emit(monument.id_objects);
      }
    });
  }

  public setTime(input: string): string {
    return (input.length > 1) ? input : '0' + input;
  }


  public setStartWay(): void {
    this.arrayStart = [];
    this.arrayStop = [];
    let curr = new Date('2019-01-01T' + this.ShowHourStart() + ':00Z').getTime() / 1000;
    if (this.infoAboutWay.length > 0) {

      for (let i = 1; i < this.monumentsArray.length; i++) {
        const tmpDate = new Date(this.monumentsArray[i - 1].time_tour === null ? '2019-01-01T00:00:00Z' : '2019-01-01T' + this.monumentsArray[i - 1].time_tour + 'Z').getTime() / 1000;
        const diff = tmpDate - new Date('2019-01-01T00:00:00Z').getTime() / 1000;
        curr = curr + diff;

        this.arrayStart.push(this.setTime((Math.floor(curr / 3600) % 24).toString()) + ':'
          + this.setTime(((curr / 60) % 60).toString()));

        const tmpList = this.infoAboutWay[i - 1]['duration'].replace('h', '').replace('min', '').replace('.', '').split(' ');
        const tmpNextDate = (tmpList.length > 1) ?
          new Date('2019-01-01T' + this.setTime(tmpList[0]) + ':' + this.setTime(tmpList[1]) + ':00Z').getTime() / 1000 :
          new Date('2019-01-01T00:' + this.setTime(tmpList[0]) + ':00Z').getTime() / 1000;
        const diff2 = tmpNextDate - new Date('2019-01-01T00:00:00Z').getTime() / 1000;

        curr = curr + diff2;
        this.arrayStop.push(this.setTime((Math.floor(curr / 3600) % 24).toString()) + ':'
          + this.setTime(((curr / 60) % 60).toString()));
      }


      if (this.monumentsArray.length > 0) {
        const timeLastMon = new Date(this.monumentsArray[this.monumentsArray.length - 1].time_tour === null ? '2019-01-01T00:00:00Z' : '2019-01-01T' + this.monumentsArray[this.monumentsArray.length - 1].time_tour + 'Z').getTime() / 1000;
        const diff = timeLastMon - new Date('2019-01-01T00:00:00Z').getTime() / 1000;
        curr = curr + diff;
        this.arrayStop.push(this.setTime((Math.floor(curr / 3600) % 24).toString()) + ':' + this.setTime(((curr / 60) % 60).toString()));
        this.setDuration(curr);
        this.setDistance();

      } else {
        this.duration = null;
        this.distance = null;
      }
    }

  }


  public setDuration(lastCurr) {
    const curr = new Date('2019-01-01T' + this.ShowHourStart() + ':00Z').getTime() / 1000;
    const tripTime = lastCurr - curr;

    if ((tripTime / 86400) >= 1) {
      this.tripService.outOfDayObs.next(true);
    } else {
      this.tripService.outOfDayObs.next(false);
    }

    this.duration = this.getFormattedTime(this.setTime((Math.floor(tripTime / 3600)).toString()) + ':'
      + this.setTime(((tripTime / 60) % 60).toString()));

  }

  public setDuration2(lastCurr) {

    let curr = (new Date('2019-01-01T' + this.ShowHourStart() + ':00Z').getTime()
      - new Date('2019-01-01T00:00:00Z').getTime()) / 1000;
    curr = lastCurr - curr;

    this.duration = this.getFormattedTime(this.setTime((Math.floor(lastCurr / 3600) % 24).toString()) + ':'
      + this.setTime(((lastCurr / 60) % 60).toString()));


  }

  public setDistance() {
    this.distance = 0;
    for (let i = 0; i < this.infoAboutWay.length; i++) {
      this.distance += parseInt(this.infoAboutWay[i].distance.replace('km', '').trim(), 10);
    }

    for (let i = 0; i < this.monumentsArray.length; i++) {
      if (this.monumentsArray[i].hasOwnProperty('distance') && !!this.monumentsArray[i].distance) {
        this.distance += Math.round(this.monumentsArray[i].distance / 1000);
      }
    }

  }


}
