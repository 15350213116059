import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import {DistanceDuration, Monument} from '../models/models.map';
import { ApiService } from 'src/app/shared/api.service';
import { ResponseApi } from 'src/app/shared/models/api-models';
import { DeviceService } from 'src/app/shared/device.service';

@Injectable({
  providedIn: 'root'
})
export class DirectionService {

  public  mobileKey = 'AIzaSyBWAAdx8SEhmixy_-7DakQF2ipLy3WJlw8';
  public  webKey = 'AIzaSyBWAAdx8SEhmixy_-7DakQF2ipLy3WJlw8';


  public directionSubject = new Subject<any>();
  public directionSubjectWithOutHideObj = new Subject<any>();
  public distanceToTarget = new Subject<any>();
  constructor(
    private http: HttpClient,
    public apiService: ApiService,
    public deviceService: DeviceService
  ) { }


  private preparareLatLng(input): string {
    return input.lat + ',' + input.lng;
  }


  public prepareParam(inputArray: Array<Monument>): any {
    if (Array.isArray(inputArray)) {
      if (inputArray.length === 0 ) {
        return null;
      }
      if (inputArray.length === 1) {
        return null;
      }
      if (inputArray.length === 2) {
        return {
          origin: this.preparareLatLng(inputArray[0].coordinates[0]),
          destination: this.preparareLatLng(inputArray[1].coordinates[0]),
          waypoints: null
        };
      }
      if (inputArray.length > 2) {
        const tmpArray = inputArray.slice(1, inputArray.length - 1);
        const waypointsArray = [];
        tmpArray.forEach(tmp => {
          waypointsArray.push(this.preparareLatLng(tmp.coordinates[0]));
        });
        return {
          origin: this.preparareLatLng(inputArray[0].coordinates[0]),
          destination: this.preparareLatLng(inputArray[inputArray.length - 1].coordinates[0]),
          waypoints: waypointsArray
        };
      }
    }
  }

  public getRoadFromGoogleMaps (inputData: any, modeOfTransport = 2, optimize = 'false'): Observable<any> {
    // waypoints=optimize:true

    let mode = '';
    switch (modeOfTransport) {

      case(0):
        mode = 'walking';
      break;

      case(1):
        mode = 'bicycling';
      break;

      case(2):
        mode = 'driving';
      break;
    }

    const optimizeStr = 'optimize:' + optimize + '|';
    let prepareUrl = '&origin=' + inputData.origin + '&destination=' + inputData.destination + '&waypoints=' + optimizeStr;
    const waypoints = inputData.waypoints;

    if (!!waypoints) {
      for ( let i = 0 ; i < waypoints.length; i++) {
        const waypoint = waypoints[i];
        prepareUrl = (i !== waypoints.length - 1) ? prepareUrl + 'via:' + waypoint + '|' : prepareUrl + 'via:' + waypoint;
      }
    }

    const urlStr = 'https://maps.googleapis.com/maps/api/directions/json?' + prepareUrl + '&mode=' + mode + '&key=';

    if (this.deviceService.getPlatform() === 'browser') {
      return this.http.get<ResponseApi>(this.apiService.apiUrl + 'map/fetch?', {params: new HttpParams().set('url', urlStr + this.webKey)});
    } else {
      return this.http.get(urlStr + this.mobileKey);
    }

  }

  public getRoadFromGoogleMapsOptimize (inputData: any, modeOfTransport = 2, optimize = 'true'): Observable<any> {
    // waypoints=optimize:true

    let mode = '';
    switch (modeOfTransport) {

      case(0):
        mode = 'walking';
      break;

      case(1):
        mode = 'bicycling';
      break;

      case(2):
        mode = 'driving';
      break;
    }

    const optimizeStr = 'optimize:' + optimize + '|';
    let prepareUrl = '&origin=' + inputData.origin + '&destination=' + inputData.destination + '&waypoints=' + optimizeStr;
    const waypoints = inputData.waypoints;

    if (!!waypoints) {
      for ( let i = 0 ; i < waypoints.length; i++) {
        const waypoint = waypoints[i];
        prepareUrl = (i !== waypoints.length - 1) ? prepareUrl  + waypoint + '|' : prepareUrl + waypoint;
      }
    }

    const urlStr = 'https://maps.googleapis.com/maps/api/directions/json?' + prepareUrl + '&mode=' + mode + '&key=';

    if (this.deviceService.getPlatform() === 'browser') {
      return this.http.get<ResponseApi>(this.apiService.apiUrl + 'map/fetch?', {params: new HttpParams().set('url', urlStr + this.webKey)});
    } else {
      return this.http.get(urlStr + this.mobileKey);
    }

  }

}

