import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {Trip} from '../map/models/models.map';
import {ApiService} from '../shared/api.service';
import {LocalStorageService} from '../shared/local-storage.service';
import {TripService} from '../map/services/trip.service';
import {SwiperComponent} from 'ngx-useful-swiper';

@Component({
  selector: 'app-trip',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.scss']
})
export class TripComponent implements OnInit {

  @ViewChild('similaryTripSwiper') similaryTripSwiper: SwiperComponent;

  public tripDetails: Trip;
  public similarTripsArray: Array<any> = [];
  public config: any = {
    direction: 'horizontal',
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    pagination: false,
    slidesPerView: 5,
    spaceBetween: 20,
    breakpoints: {
      // when window width is <= Xpx
      479: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      991: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1439: {
        slidesPerView: 4,
        spaceBetween: 20
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private tripService: TripService
  ) {
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.activatedRoute.data.subscribe((data: Array<any>) => {
      this.tripDetails = data['details']['trip'];
    });

    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.apiService.getSimilaryTrips(this.tripDetails.id_trips).subscribe(response => {
      this.similarTripsArray = response['data']['similar_trips'];
    });

    this.tripService.newTripBtnObs.next(true);
  }

  // funkcja przekierowuje do widoku wycieczki na mapie
  public redirectMap() {
    this.router.navigate(['map', this.tripDetails.id_trips]);
  }

  public getFormattedTime(dataTime) {
    if (dataTime === null) {
      dataTime = '01:00:00';
    }

    let html = '';
    let time = dataTime.split(':');
    for (let i = 0; i < 2; i++) {
      while (time[i].charAt(0) === '0') {
        time[i] = time[i].substr(1);
      }
    }

    if (time[0] !== '') {
      /** godziny */
      html += time[0] + 'h';

      /** minuty */
      if (time[1] !== '') {
        html += ' ' + time[1] + 'min.';
      }
    } else {
      if (time[1] !== '') {
        /** tylko minuty */
        html += time[1] + 'min.';
      } else {
        html += '1h';
      }
    }

    return html;
  }

  public getFormattedDistance(distance) {
    let html = '';
    if (distance === null) {
      html += '0km';
    } else {
      html += Math.round(distance / 10) / 100 + ' km';
    }

    return html;
  }

  // zwaraca 20 pierwszych wyrazów
  public getShortDesc(text: string, words: number): string {
    if (text === null) {
      return '';
    } else if (text.trim().split(' ').length <= words) {
      return text.trim();
    } else if (text.trim().split(' ').length > words) {
      const resultArray = text.trim().split(' ').slice(0, words);
      return resultArray.join(' ') + '...';
    }
  }

  public similaryTripDetails(id) {
    this.router.navigate(['trip', id]);
    this.localStorageService.setItem('Guide', 'saved-trip', false);
  }

}
