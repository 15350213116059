import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, empty} from 'rxjs';
import {DeviceService} from './device.service';
import {catchError} from 'rxjs/operators';
import {ToastMaker} from '../map/models/models.map';
import {ToastService} from './toast.service';
import {ApiService} from './api.service';

// objekt
declare var device;

/**
 * Interceptor przechwyci wychodzący HttpRequest i opcjonalnie przekształci go.
 * W naszym przypadku interseptor doda do headera obecny token
 * oraz obsłuży błąd nieprawidłowego tokena
 */

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.getRequest(req))
      .pipe(
        catchError((err, caught) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              // blad serwera wylogowanie user-a
              case 500:
                this.toast.subjectToast.next(ToastMaker.create({
                  message: 'Błąd serwera. Skontaktuj się z nami, aby wyjaśnić sytuację.',
                  time: 6000,
                  type: 'error'
                }));
                break;

              // wiadomości 400
              case 400:
                this.toast.subjectToast.next(ToastMaker.create({message: err.error.data.message, time: 4000, type: 'error'}));
                break;

              // wygasła sesja user-a
              case 401:
                this.toast.subjectToast.next(ToastMaker.create({message: err.error.data.message, time: 4000, type: 'error'}));
                break;

              // widomości 403
              case 403:
                this.toast.subjectToast.next(ToastMaker.create({message: err.error.data.message, time: 4000, type: 'error'}));
                break;
              // widomości 404
              case 404:
                this.toast.subjectToast.next(ToastMaker.create({message: err.error.data.message, time: 4000, type: 'error'}));
                break;
            }
            this.apiService.showLoaderObs.next(false);
          }
          return empty();
        })
      );
  }

  public getRequest(req: HttpRequest<any>): HttpRequest<any> {

    let uuid = this.deviceService.getDeviceUuid();
    let version = this.deviceService.getDeviceVersion();
    let os = this.deviceService.getPlatform();
    let model = this.deviceService.getModel();

    version = ((!!version) ? version : '1.0');
    os = ((!!os) ? os : '666');
    model = ((!!model) ? model : '333');
    uuid = ((!!uuid) ? uuid : 'Browser');

    if (req.url.indexOf('maps.googleapis.com/maps/api/directions/json') !== -1) {
      return req;
    }


    let reqClone: HttpRequest<any> = null;
    reqClone = req.clone({
      setHeaders: {
        'ma-key': 'd133b03bc49dd40a215ccd9f51b0bd80',
        'ma-device': uuid,
        'ma-os': os,
        'ma-os_version': version,
        'ma-model': model,
      }
    });
    return reqClone;


  }

  constructor(
    private deviceService: DeviceService,
    public toast: ToastService,
    public apiService: ApiService
  ) {
  }


}
