import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/shared/api.service';
import { DeviceService } from '../shared/device.service';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {

  public cookieActive = true;

  constructor(
    private cookieService: CookieService,
    public apiService: ApiService,
    public deviceService: DeviceService
  ) { }

  ngOnInit() {
    if (this.cookieService.check('cookieActive')) {
      const cookieValue: string = this.cookieService.get('cookieActive');
      if (cookieValue === '0') {
        this.cookieActive = false;
      }
    }
  }

  public cookieClose() {
    this.cookieActive = false;
    this.cookieService.set('cookieActive', '0', 30);
  }

  public rules(): void {
    this.apiService.pageObs.next('3');
  }
}
