import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MapComponent} from './map/map.component';
import {StartComponent} from './start/start.component';
import {MapResolve} from './map/models/map.resolver';
import {StartResolve} from './start/model/start.resolver';
import {TripComponent} from './trip/trip.component';
import {TripDetailResolve} from './trip/model/trip.resolver';
import {MapCreateTripComponent} from './map/map-create-trip/map-create-trip.component';

const routes: Routes = [

  /** routing startowy  */
  {path: '', redirectTo: '/map/new_trip', pathMatch: 'full'},

  /** routing startowy */
  {
    path: 'start', data: {'name': 'Start', 'barVisible': false}, component: StartComponent, resolve: {'trips': StartResolve}
  },
  /** routing szczegołow */
  {
    path: 'trip/:id', data: {'name': 'Details', 'barVisible': false}, component: TripComponent, resolve: {'details': TripDetailResolve}
  },
  /** routing mapy */
  {
    path: 'map/:id', data: {'name': 'Mapa', 'barVisible': true}, component: MapComponent, resolve: {'monuments': MapResolve}
  },
  /** routing obiektu */
  {
    path: 'map/accommodations/:id', data: {'name': 'Accommodations', 'barVisible': true}, component: MapComponent, resolve: {'monuments': MapResolve}
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, {useHash: true}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
