import {Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {MapService} from '../services/map.service';
import {TripService} from '../services/trip.service';
import {ApiService} from '../../shared/api.service';
import {ToolSetService} from '../services/tool-set.service';
import {Monument} from '../models/models.map';
import {ObjectMenuComponent} from '../object-menu/object-menu.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {

  @ViewChild('navLeft') navLeft;

  @Input('monumentDesc') monumentDesc: ObjectMenuComponent;

  @Output('selectedPin') selectedPin = new EventEmitter<any>();
  @Output('favoritesObjectsEmit')  favoritesObjectsEmit = new EventEmitter<any>();
  @Output('cordsFromSearch') cordsFromSearch = new EventEmitter<any>();

  public toggleFlag = false;
  public favoriteObjects = new Array<Monument>();
  public maxResult = 20;
  public page = 1;
  public subscription = new Subscription();

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  constructor (
    public mapService: MapService,
    public tripService: TripService,
    public apiService: ApiService,
    public toolService: ToolSetService,
    public renderer: Renderer2
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.mapService.toggleMenuItemObs.subscribe(info => {
        if (info.state && info.action === 'favorite') {
          this.open();
        } else {
          if (this.toggleFlag) {
            this.hide();
          }
        }
      })
    );
  }


  public hide(): void {
    if (this.toggleFlag === true) {
      this.toggleFlag = false;
      this.renderer.addClass(this.navLeft.nativeElement, 'closed');
    }
  }

  public open() {
    this.apiService.showLoaderObs.next(true);
    this.toggleFlag = true;
    this.mapService.groupMonuments.next(false);
    this.renderer.removeClass(this.navLeft.nativeElement, 'closed');

    const tmpArray = [];
    this.apiService.getFavoriteObjects().subscribe(
      response => {
        const favoritesArray  = response['data']['favorites'];
        this.apiService.getMonuments().subscribe( answer => {
          const answerArray: Array<Monument> = answer['data']['objects'];
          for (let i = 0; i < favoritesArray.length; i++) {
            const monument_id = favoritesArray[i].id_objects;
            const isObject = answerArray.find(obj => { return obj['id_objects'] === monument_id;
            });
            tmpArray.push(isObject);
          }
          this.favoriteObjects = tmpArray;
          this.favoritesObjectsEmit.emit(tmpArray);
          this.apiService.showLoaderObs.next(false);
        });
      });
  }

  /** zwaraca 20 pierwszych wyrazów */
  public getShortDesc (text: string, words: number): string {
    if (text === null) {
      return '';
    } else if (text.trim().split(' ').length <= words ) {
      return text.trim();
    } else if (text.trim().split(' ').length > words) {
      const resultArray = text.trim().split(' ').slice(0, words);
      return resultArray.join(' ') + '...';
    }
  }

  /** pokazuje informacje o monumencie */
  public showInfoMonument(monumentInfo: Monument) {
    this.apiService.getMonument(monumentInfo.id_objects.toString()).subscribe( response => {
      const object = response['data']['object'];
      if (object) {
        this.monumentDesc.writeData(object);
        this.mapService.toggleMenuItemObs.next({state: true, action: 'infoMonument'});
        this.selectedPin.emit(object.id_objects);
      }
    });
  }
}
