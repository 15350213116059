import {Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation} from '@angular/core';
import { ApiService } from '../shared/api.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactComponent implements OnInit {

  @ViewChild('contact') contact: ElementRef;

  @Input('menuVisible') menuVisible: Boolean;

  public content;
  public title = '';

  public contactStatus = false;

  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {



    this.apiService.pageObs.subscribe(res => {
      if (res === '4') {
        if (this.title === '') {
          this.apiService.getPage('481').subscribe(response => {
            if (response.status['success']) {
              this.content = this.sanitizer.bypassSecurityTrustHtml(response['data']['page']['content']);
              this.title = response['data']['page']['name'];
              this.contactStatus = true;
            }
          });
        } else {
          this.contactStatus = true;
        }

      } else {
        this.contactStatus = false;
      }
    });
  }

  public close() {
    this.apiService.pageObs.next(null);
  }

}
