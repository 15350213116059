import {Injectable} from '@angular/core';
import {Observable, Subject, BehaviorSubject} from 'rxjs';
import {ApiService} from '../../shared/api.service';
import * as geodist from 'geodist';
import {Monument} from '../models/models.map';
import {MapsAPILoader} from '@agm/core';
import {LocalStorageService} from 'src/app/shared/local-storage.service';


declare var google: any;


@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private apiService: ApiService,
              private _loader: MapsAPILoader,
              public localStorageService: LocalStorageService
  ) {
  }

  public tripDaysObserver = new Subject<number>();

  /* zamyka wszytkie okna */
  public subject = new Subject<string>();

  /** otwiera zamyka toolbar */
  public toolBarSubject = new Subject<any>();

  public toggleMenuItemObs = new Subject<any>();

  /** grupowanie obiektów na mapie*/
  public groupMonuments = new Subject<any>();

  /** otwiera zamyka infoDay */
  public infoDay = new Subject<any>();


  /** otwiera zamyka infoTrip */
  public infoTrip = new Subject<any>();

  public renderFist = new Subject<any>();

  public allObjectArray: Array<Monument> = [];

  private geocoder: any;

  public setAllObjectArray(array: Array<Monument>): void {
    this.allObjectArray = array;
  }

  public getAllObjects(): Array<Monument> {
    return this.allObjectArray;
  }

  public getMonumentByCords(cords, allObjectArray): Monument {
    let objTmp: Monument = null;
    objTmp = this.findClosedMarker(cords, null, allObjectArray);
    return objTmp;
  }


  public initGeocoder() {
    this.geocoder = new google.maps.Geocoder();
  }

  /** funkcja zwraca boundaries mapy */
  public getMapBounds(map): any {
    const bounds = map.getBounds();
    return {
      nw: {
        lat: bounds.getNorthEast().lat(), // N
        lng: bounds.getSouthWest().lng(), // W
      },
      se: {
        lat: bounds.getSouthWest().lat(), // S
        lng: bounds.getNorthEast().lng(), // E
      }
    };
  }

  /**
   * funkcja śledzi polożenie użytkownika po naciśnięciu lokalizacji
   */

  public getLocation(): Observable<any> {
    return Observable.create(observer => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {

            observer.next(position.coords);

          },
          (error) => observer.error('Nie można odczytać pozycji')
        );
      } else {
        observer.error('Nie można odczytać pozycji');
      }
    });
  }

  public ToFloat(latitude: string): number {
    return parseFloat(latitude);
  }


  public getClosedPoint(start, list): number {
    let dist = 0;
    let closeDistance = 9999999999999999999999999;
    let indexToSave = 0;

    for (let i = 0; i < list.length; i++) {
      dist = geodist(start, list[i], {exact: true, unit: 'm'});
      if (dist < closeDistance) {
        closeDistance = dist;
        indexToSave = i;
      }
    }
    return indexToSave;
  }

  /**
   * zwaraca najbliższy monument po upuszczecniu pineski
   */
  public findClosedMarker(dropCords, lastPosition, monuments): Monument {
    let closeDistance = 999999999999999999999999;
    let closedMonument;
    let accommodation;
    monuments.forEach((monument) => {
      if (monument.hasOwnProperty('coordinates')) {
        const monumentCords = monument['coordinates'];
        if (Array.isArray(monumentCords) && monumentCords.length > 0) {
          const dist = geodist(dropCords, monumentCords[0], {exact: true, unit: 'km'});
          if (dist < closeDistance) {
            closeDistance = dist;
            closedMonument = monument;
            accommodation = monument['accommodation'];
          }
        }
      }
    });
    if (accommodation === 1) {
      return null;
    } else {
      return closedMonument;
    }
  }

  /* zwraca cordinates najblizszego markera */
  public findClosedMarkerCords(dropCords, lastPosition, monuments): any {
    const nearMarker = this.findClosedMarker(dropCords, lastPosition, monuments);
    if (nearMarker === null) {
      return null;
    } else {
      const nearMarkerCords = nearMarker['coordinates'][0];
      nearMarkerCords.lat = this.ToFloat(nearMarkerCords.lat);
      nearMarkerCords.lng = this.ToFloat(nearMarkerCords.lng);
      return nearMarkerCords;
    }
  }


  public getClosedPointFromDirection(route: Array<any>, cordinates: Array<any>): Array<any> {
    const indexArray = [];
    for (let i = 0; i < cordinates.length; i++) {
      let closeDistance = 999999999999999999999999;
      let tmpIndex = 0;
      const tmpCords = cordinates[i][0];
      tmpCords.lat = this.ToFloat(tmpCords.lat);
      tmpCords.lng = this.ToFloat(tmpCords.lng);

      for (let j = 0; j < route.length; j++) {
        const dist = geodist(tmpCords, route[j], {exact: true, unit: 'm'});

        if (dist < closeDistance) {
          closeDistance = dist;
          tmpIndex = j;
        }
      }
      indexArray.push(tmpIndex);
    }

    return indexArray;
  }

  public getClosedPointFromDirectionEnd(route: Array<any>, cordinates: Array<any>): number {
    let closeDistance = 999999999999999999999999;
    let tmpIndex = 0;
    const tmpCords = cordinates[cordinates.length - 1];
    tmpCords.lat = this.ToFloat(tmpCords.lat);
    tmpCords.lng = this.ToFloat(tmpCords.lng);

    for (let j = 0; j < route.length; j++) {
      const dist = geodist(tmpCords, route[j], {exact: true, unit: 'm'});

      if (dist < closeDistance) {
        closeDistance = dist;
        tmpIndex = j;
      }
    }

    return tmpIndex;
  }

  getGeocoding(city: string, address: string) {
    const country = 'Poland';
    const postal = address;
    return Observable.create(observer => {
      try {
        this._loader.load().then(() => {
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({address: city + ',' + postal + ', ' + country}, (results, status) => {

            if (status === google.maps.GeocoderStatus.OK) {
              const place = results[0].geometry.location;
              observer.next(place);
              observer.complete();
            } else {
              if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                observer.error('Address not found!');
              } else {
                observer.error(status);
              }

              observer.complete();
            }
          });
        });
      } catch (error) {
        observer.error('error getGeocoding' + error);
        observer.complete();
      }

    });
  }


  public reverseArr(input) {
    const ret = new Array;
    for (let i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  public showPosition(position) {
    return {lat: position.coords.latitude, lng: position.coords.longitude};
  }

}
