import { Component, OnInit } from '@angular/core';
import { DeviceService } from '../shared/device.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-connection',
  templateUrl: './no-connection.component.html',
  styleUrls: ['./no-connection.component.scss']
})
export class NoConnectionComponent implements OnInit {

  constructor(
    public deviceService: DeviceService,
    public router: Router,
  ) { }

  ngOnInit() {
  }

  public refresh(): void {
    this.router.navigateByUrl(this.router.url);
  }
}
