import { Component, OnInit, ViewChild } from '@angular/core';
import { AgmMap } from '@agm/core';
import { ApiService } from 'src/app/shared/api.service';
import { Trip, Monument } from '../models/models.map';
import { ObjectMenuComponent } from '../object-menu/object-menu.component';
import { MapService } from '../services/map.service';

@Component({
  selector: 'app-map-create-trip',
  templateUrl: './map-create-trip.component.html',
  styleUrls: ['./map-create-trip.component.scss']
})
export class MapCreateTripComponent implements OnInit {


    /** początkowe wartości */
    public lat = 50.5407983;
    public lng = 18.0577103;
    /** idTypuMapy */
    public idTypeMap = 'roadmap';
    /** wszytkie obiekty istniejące na mapie */
    public allObjects: Array<Monument>;
    /** wszytkie tripy w /:id */
    public trip: Trip = null;
    /** wszytkie obiekty w /:id */
    public tripObjectArray: Array<any> = [];
    /** typ mapy */
    public typeOfView: string;
    /** zoom mapy */
    public zoom = 9;
    /** referencja do mapy */
    @ViewChild('map') mapContainer: AgmMap;
    @ViewChild('monumentDesc') monumentDesc: ObjectMenuComponent;
    /** poczatkowy i koncowy punkt */
    public origin = null;
    public destination = null;
    /** opcje rendenderowania bez markera */
    public renderOptions = {
      suppressMarkers: true
    };
    /**
     * deklaracja waypoint
     * przykladowy zapis w komentarzu
     */
    public waypoints = [];
    /** current monument */
    public clickedMonument: any;
    constructor(
       private mapService: MapService,
       private apiService: ApiService
      ) { }

      /*
     * w ngOnInit nastepuje podzial danych w mapie na pochodzace z /create i z /:id
     * tryb wszytkich obiektow - /create
     * tryb wycieczki - /:id
     */
    ngOnInit() {

      this.getMonuments();
      this.mapService.subject.subscribe(  answer => {
        if (answer === 'closeInfoToolbar') {
          this.monumentDesc.hide();
        }
      });
    }


    /** pobieranie wszytkich obiektow na mapie */
    public getMonuments() {
      this.apiService.getMonuments().subscribe(response => {
        this.allObjects = response['data']['objects'];
      });
    }

    /** ustawienie wayPointow dla mapy */
    public setWayPoints() {
      const wayPointsProcess = this.tripObjectArray.slice(1, -1);
      wayPointsProcess.forEach(element => {
        const  latFloat  = this.latitudeToFloat(element.coordinates[0].lat);
        const  lngFloat  = this.longitudeToFloat(element.coordinates[0].lng);
        const waypoint =  {
                  location: { lat: latFloat, lng: lngFloat },
                  stopover: false
              };
        this.waypoints.push(waypoint);
      });
    }
  /** ustawia routing dla mapy start i stop */
    public setStartTrip(indexStart) {
      this.origin = {
                      lat: this.tripObjectArray[indexStart].coordinates[0].lat,
                      lng: this.tripObjectArray[indexStart].coordinates[0].lng
                    };
      /** przygotowanie do routingu */
      this.origin.lat = this.longitudeToFloat(this.origin.lat);
      this.origin.lng = this.longitudeToFloat(this.origin.lng);
    }
    public setStopTrip(indexStop) {
      this.destination = {
                            lat: this.tripObjectArray[indexStop].coordinates[0].lat,
                            lng: this.tripObjectArray[indexStop].coordinates[0].lng
                          };
      this.destination.lat = this.longitudeToFloat(this.destination.lat);
      this.destination.lng = this.longitudeToFloat(this.destination.lng);
    }

  /**
   * funkcja zamienia latitude na number
   */
   public latitudeToFloat(latitude: string): number {
     return parseFloat(latitude);
   }

   /**
    * longitude na number
    */
   public longitudeToFloat(longitude: string): number {
     return parseFloat(longitude);
   }


  /** funkcja uruchamiana po kliknieciu lokalizuj  */
  public locationPress() {
    this.mapService.getLocation().subscribe(pos => {
      this.lat = parseFloat(pos.latitude);
      this.lng = parseFloat(pos.longitude);
      this.zoom = 12;
    });
  }

  /** pobiera dane i wysyla je do bocznego komponentu z lewej */
  public monumentDetail(id: string) {
    this.apiService.getMonument(id).subscribe(response => {
      this.clickedMonument = response['data'];
      this.monumentDesc.writeData(this.clickedMonument);
    });
  }

  /** ustawia kolor dla obiektu liniowego */
  public getLineColor(color): string {
    return (color === null ? 'red' : '#' + color);
  }
}
