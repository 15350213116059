import {Component, OnInit, Renderer2, ViewChild, ElementRef, Input, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MapService} from '../services/map.service';
import {ApiService} from '../../shared/api.service';
import {LocaleService} from '../../share/locale.service';
import {Catergory, Monument, Trip} from '../models/models.map';
import {NgForm} from '@angular/forms';
import {HttpParams} from '@angular/common/http';
import {ObjectMenuComponent} from '../object-menu/object-menu.component';
import {SearchService} from '../services/search.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {forEach} from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  /** referecja do lewego okienka */
  @ViewChild('navLeft') navLeft: ElementRef;
  @ViewChild('icon') icon: ElementRef;
  @ViewChild('categories') categories: ElementRef;
  /** categoryForm - formularz categori  */
  @ViewChild('categoryForm') categoryForm: NgForm;

  @Input('monumentDesc') monumentDesc: ObjectMenuComponent;
  @Input('allObjects') allObjects: Array<any>;
  @Input('amountObjects') amountObjects: number;
  @Input('trip') trip: Trip;
  @Input('mapBounds') mapBounds;


  @Output('zoomCity') zoomCity = new EventEmitter<string>();
  @Output('cordsFromSearch') cordsFromSearch = new EventEmitter<any>();
  @Output('selectedPin') selectedPin = new EventEmitter<any>();
  @Output('searchedObjects') searchedObjects = new EventEmitter<any>();
  @Output('checkOnBoundsSearch') checkOnBoundsSearch = new EventEmitter<any>();

  public categoriesToggle = false;
  public localizationsAll: Array<any> = null;
  public localizations: Array<any> = null;

  public resultSearch = new Array<Monument>();
  public resultSearchArchive = new Array<Monument>();
  public promotedTrips = new Array<Monument>();

  private subjectSearch: Subject<any> = new Subject();
  public subscription = new Subscription();

  public maxResult = 20;

  public page: number = 1;

  public cityValue = '';
  public searchValue = '';

  public categoriesArray: Array<Catergory> = [];
  public selectedCategories: Array<any> = [];

  public searchShow = false;

  public toggleFlagBoudaries = false;

  public renderFirst = true;

  public toggleFlag = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private mapService: MapService,
    private localStorage: LocaleService,
    private apiService: ApiService,
    private searchService: SearchService,
  ) {
  }

  ngOnInit() {
    /** pobranie kategorii */
    this.categoriesArray = this.localStorage.getItem('Guide', 'Categories');

    this.subscription.add(
      this.mapService.toggleMenuItemObs.subscribe(info => {
        if (info.state && info.action === 'ToolBar') {
          this.open(info.action);
        } else {
          if (this.toggleFlag) {
            this.hide();
          }
        }
      })
    );

    this.searchService.closeCheckBox.subscribe(res => {
      this.changeBox(!res.close);
    });

    this.apiService.getRecommendedTrips().subscribe(response => {
      this.promotedTrips = response['data']['trips']['trips'];
    });

    this.updateSearchFormObs();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /** ukrywa pasek boczny */
  public hide(): void {
    if (this.toggleFlag === true) {
      this.searchValue = '';
      this.categoryForm.reset();
      this.toggleFlag = false;
      this.renderer.addClass(this.navLeft.nativeElement, 'closed');
    }
    this.searchValue = '';
    this.selectedCategories = [];
    this.categoryForm.reset();
  }

  /**
   * funkcja pokazuje pasek boczny
   */
  public open(action: string = null): void {
    this.apiService.showLoader();
    this.localStorage.removeItem('Guide', 'FirstDayHide');

    this.toggleFlag = true;
    this.mapService.groupMonuments.next(true);
    this.renderer.removeClass(this.navLeft.nativeElement, 'closed');
    this.monumentDesc.hide();
    this.apiService.getCities().subscribe(response => {
      this.localizationsAll = response['data']['localizations'];
    });

    /** wyciągnięcie wszystkich obiektów na start */
    this.getSearchedMonuments(this.searchValue, this.selectedCategories);
  }

  public getSearchedMonuments(searchValue: string, categories: Array<any>) {
    this.apiService.getSearchedObjects(searchValue, 'null', new HttpParams().set('categories', '[' + categories.toString() + ']')).subscribe(res => {
      this.resultSearchArchive = res.data.objects;
      this.resultSearch = (this.toggleFlagBoudaries) ? this.showOnlyFromBoudaries(this.resultSearchArchive) : this.resultSearchArchive;
      const tmpArray = this.resultSearch.map((item) => {
        return item.id_objects;
      });
      this.searchedObjects.emit(tmpArray);
      this.apiService.hideLoader();
    });
  }

  public updateSearchFormObs() {
    this.subjectSearch.pipe(debounceTime(1000)).subscribe(param => {

      if (param) {
        this.getSearchedMonuments(this.searchValue, this.selectedCategories);
      } else {
        this.resultSearch = (this.toggleFlagBoudaries) ? this.showOnlyFromBoudaries(this.resultSearchArchive) : this.resultSearchArchive;
        const tmpArray = this.resultSearch.map((item) => {
          return item.id_objects;
        });
        this.searchedObjects.emit(tmpArray);
        this.apiService.hideLoader();
      }

    });
  }


  public prepareDataForm() {
    const categories = [];
    for (const key in this.categoryForm.value) {
      if (this.categoryForm.value.hasOwnProperty(key)) {
        const element = this.categoryForm.value[key];
        if (element) {
          categories.push(key);
        }
      }
    }
    return categories;
  }

  /** showValue - funkcja wyswietla wyniki wyszukiwania
   */
  public showValue(getFromApi: boolean): void {
    this.apiService.showLoader();
    this.subjectSearch.next(getFromApi);
  }

  public searchValueChange() {
    if (this.searchValue.length === 0) {
      this.showValue(true);
    }

    if (this.searchValue.length > 2) {
      this.showValue(true);
    }
  }

  /** pokaż kategorie */
  public showCategories() {
    this.categoriesToggle = !this.categoriesToggle;
    if (this.categoriesToggle) {
      this.renderer.addClass(this.icon.nativeElement, 'rotate');
      this.renderer.addClass(this.categories.nativeElement, 'categories-open');
    } else {
      this.renderer.removeClass(this.icon.nativeElement, 'rotate');
      this.renderer.removeClass(this.categories.nativeElement, 'categories-open');
    }
  }

  public searchCategoriesChange() {
    this.selectedCategories = this.prepareDataForm();
    this.showValue(true);
  }

  /** zwaraca 20 pierwszych wyrazów */
  public getShortDesc(text: string, words: number): string {
    if (text === null) {
      return '';
    } else if (text.trim().split(' ').length <= words) {
      return text.trim();
    } else if (text.trim().split(' ').length > words) {
      const resultArray = text.trim().split(' ').slice(0, words);
      return resultArray.join(' ') + '...';
    }
  }

  /** pokazuje informacje o monumencie */
  public showInfoMonument(monumentInfo: Monument) {
    this.apiService.getMonument(monumentInfo.id_objects.toString()).subscribe(response => {
      const object = response['data']['object'];
      if (object) {
        this.monumentDesc.writeData(object);
        this.mapService.toggleMenuItemObs.next({state: true, action: 'infoMonument'});
        this.selectedPin.emit(monumentInfo.id_objects);
      }
    });
  }

  /** funkcja zwraca wielkosc tablicy */
  public getLengthArray(inputArray: Array<any>): number {
    if (Array.isArray(inputArray)) {
      return inputArray.length;
    } else {
      return 0;
    }
  }

  /** zwaraca pasujace miasta do opisu */
  public getCities() {
    this.localizations = [];
    this.searchShow = false;

    if (!!this.cityValue && Array.isArray(this.localizationsAll)) {
      this.searchShow = true;
      this.localizationsAll.forEach(el => {
        const smallLetterCity = el.city.toLowerCase();
        const smallLetterSearch = this.cityValue.toLowerCase();
        if (smallLetterCity.startsWith(smallLetterSearch)) {
          this.localizations.push(el);
        }
      });
    }
    if (this.localizations.length === 0) {
      this.searchShow = false;
    }
  }


  public showCity(city): void {
    this.apiService.showLoader();
    this.toggleFlagBoudaries = true;
    this.zoomCity.emit(city);
    this.searchShow = false;
    this.cityValue = city.city;
  }


  public changeBox(val) {
    this.toggleFlagBoudaries = val;
    this.checkOnBoundsSearch.emit(this.toggleFlagBoudaries);
    this.showValue(val);
  }

  public showOnlyFromBoudaries(monuments: Array<any>): Array<any> {
    const retArray: Array<any> = [];

    monuments.forEach(monument => {
      if (monument.hasOwnProperty('coordinates')) {
        const coordinates = monument['coordinates'];
        if (Array.isArray(coordinates) && coordinates.length > 0) {
          if (this.searchService.filterCords(coordinates[0], this.mapBounds)) {
            retArray.push(monument);
          }
        }
      }
    });

    return retArray;
  }

  public recomemndedTrip(id) {
    this.router.navigate(['trip', id]);
    this.apiService.getRecommendedTrips().subscribe(response => {
      this.promotedTrips = response['data']['trips']['trips'];
    });
  }
}
