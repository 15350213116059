export class LatLng {
  lat: any;
  lng: any;
}


export interface Monument {
  id_objects: number;
  email: string;
  name: string;
  photo: string;
  avatar_url: string;
  description: string;
  city: string;
  district: string;
  original_city: string;
  pin_url: string;
  post_office: string;
  street: string;
  street_number: string;
  update_date: string;
  www: string;
  'zip-code': string;
  gallery: any;
  local_number: string;
  openHours: string;
  accommodation: any;
  categories: any;
  thumbnail: any;
  www_fb: string;
  www_reservation: string;
  www_twitter: string;
  www_wiki: string;
  www_yt: string;
  line_color: string;
  coordinates: Array<any>;
  time_tour: any;
  distance: number;
}

export interface MonumentShort {
  id_objects: number;
  name: string;
  photo: string;
  avatar_url: string;
  pin_url: string;
  thumbnail: any;
  accommodation: any;
  coordinates: Array<any>;
  time_tour: any;
  distance: number;
}
export class MonumentShortMaker {
  static create(event: MonumentShort) {
    return { id_objects: event.id_objects, name: event.name, photo: event.photo, avatar_url: event.avatar_url,
    pin_url: event.pin_url, accommodation: event.accommodation, coordinates: event.coordinates, time_tour: event.time_tour,
    distance: event.distance, thumbnail: event.thumbnail
  };
  }
}

export interface Toast {message: string;  time: number; type: string; }

export class ToastMaker {
  static create(event: Toast): Toast {
    return { message: event.message, time: event.time, type:  event.type };
  }
}

export class DayOfTrip {
    day: number;
    id_trips: string;
    id_trip_days: number;
    mode_of_transportation: number;
    objects: Monument[];
    time_start: string;
    time_tour: string;

    constructor(day: number, id_trip: string) {
      this.day = day;
      this.id_trips = id_trip;
      this.id_trip_days = null;
      this.mode_of_transportation = null;
      this.objects = [];
      this.time_start = null;
      this.time_tour = null;
    }
}




export class Trip {
    create_date: string;
    created_by: string;
    days: number;
    description: string;
    distance: number;
    id_device: string;
    id_trips: string;
    image: string;
    screenshot: string;
    mode_of_transportation: number;
    name: string;
    public: number;
    trip_days: DayOfTrip[];
    views: number;
    www: string;

    constructor() {}
}

export class Catergory {
  children: Catergory;
  icon: string;
  id_categories: number;
  name: string;
  parent: Catergory;
  sort: string;
}

export class DistanceDuration {
  distance: string;
  duration: string;
}


export class Track {
  origin: any;
  destination: any;
  waypoint: Array<any>;
 }
