import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from '../../shared/api.service';
import {ResponseApi} from '../../shared/models/api-models';
import {map} from 'rxjs/operators';

/**
 *  Route resolvers służy dostarczeniu danych do Routa,
 *  przed aktywacją Routa, a jeszcze prościej mówiąc,
 *  zmuszamy naszą aplikację, aby pokazał widok dopiero jak dane zostaną dostarczone.
 */

@Injectable({
  providedIn: 'root'
})
export class TripDetailResolve implements Resolve<any> {

  /**
   * funkcja zwraca dane w postaci obiektu lub observabla
   */
  resolve(route: ActivatedRouteSnapshot, stare: RouterStateSnapshot): Observable<ResponseApi> {

    const id = route.url['1'].path;
    this.api.addViews(id).subscribe();

    return this.api.getTripDetails(id).pipe(
      map(response => {
        const data = response['data'];
        return data;
      }),
    );
  }

  constructor(private api: ApiService,
  ) {
  }

}
