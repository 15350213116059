import {Component, OnInit, ViewChild, Renderer2, Output, AfterContentInit, AfterViewChecked, AfterViewInit, OnDestroy} from '@angular/core';
import {AgmMap, LatLngBounds} from '@agm/core';
import {ApiService} from '../shared/api.service';
import {ActivatedRoute} from '@angular/router';
import {Trip, Monument, DayOfTrip, Track, ToastMaker} from './models/models.map';
import {MapService} from './services/map.service';
import {ObjectMenuComponent} from './object-menu/object-menu.component';
import {LocalStorageService} from '../shared/local-storage.service';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {TripService} from './services/trip.service';
import {DirectionService} from './services/direction.service';
import {DayDetailsComponent} from './day-details/day-details.component';
import {FavoriteComponent} from './favorite/favorite.component';
import {DeviceService} from '../shared/device.service';
import {PrintTripComponent} from './print-trip/print-trip.component';

import {Subject, Subscription} from 'rxjs';
import {debounceTime, timeout} from 'rxjs/operators';
import {ToolSetService} from './services/tool-set.service';
import {TripInfoComponent} from './trip-info/trip-info.component';
import {ToastService} from '../shared/toast.service';

declare let google: any;
declare var require: any;

// http://10.0.8.30:3000/#/map/a4f92f73
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {


  /** początkowe wartości */
  public lat = 50.5407983;
  public lng = 18.0577103;


  public showCluster = true;

  public map: any;

  /** idTypuMapy */
  public idTypeMap = 'roadmap';

  /** bounds mapy */
  public mapBounds = null;

  private subscription: Subscription;
  private subscriptionDirection: Subscription;
  private subscriptionDirection2: Subscription;


  /** wszytkie obiekty istniejące na mapie */
  public allObjects: Array<Monument> = [];
  public allObjectsCopy: Array<Monument> = [];
  public amountObjects: any = 0;

  /** wszytkie tripy w /:id */
  public trip: Trip = null;
  /** wszytkie obiekty w /:id */
  public tripObjectArray: Array<any> = [];


  /** typ mapy */
  public typeOfView: string;

  /** zoom mapy */
  public zoom = 9;

  /** flaga optymalizacji  */
  public optimizeWaypoints = true;

  /** tablica trasy - zawiera poszczegolne kroki trasy */
  public routeArray = [];

  /** referencja do mapy */
  @ViewChild('map') mapContainer: AgmMap;

  @ViewChild('monumentDesc') monumentDesc: ObjectMenuComponent;

  @ViewChild('appToolbar') appToolbar: ToolbarComponent;

  @ViewChild('direction') direction;

  @ViewChild('dayDetail') dayDetail: DayDetailsComponent;

  @ViewChild('favorite') favorite: FavoriteComponent;

  @ViewChild('printTrip') printTrip: PrintTripComponent;

  @ViewChild('tripInfo') tripInfoCom: TripInfoComponent;

  public indexOfDay = 0;

  private subjectBouds: Subject<any> = new Subject();

  /** zmienna odpowiedzialna za automatyczne wyszukiwanie */
  public toggleAutomaticSearch = true;

  /** poczatkowy i koncowy punkt */
  public origin = null;

  public destination = null;

  public renderOptions = {
    suppressMarkers: true,
    draggable: false,
    preserveViewport: true
  };

  /**
   * deklaracja waypoint
   * przykladowy zapis w komentarzu
   */
  public waypoints = [];
  public variable = 0;

  /** current monument */
  public clickedMonument: any;


  public monumentsArray: Array<Monument> = [];

  public selectedMonument = null;
  public selectedPin = null;

  public loader = true;

  public groupMonuments = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private mapService: MapService,
    private apiService: ApiService,
    private renderer: Renderer2,
    private localStorageService: LocalStorageService,
    private tripService: TripService,
    private directionService: DirectionService,
    private deviceService: DeviceService,
    public toolSetService: ToolSetService,
    public toast: ToastService
  ) {

    this.subscription = this.toolSetService.printSubject.subscribe(answer => {
      if (answer.print === true) {
        window.print();
      }
    });
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionDirection.unsubscribe();
    this.subscriptionDirection2.unsubscribe();
  }

  /*
   * w ngOnInit nastepuje podzial danych w mapie na pochodzace z /create i z /:id
   * tryb wszytkich obiektow - /create
   * tryb wycieczki - /:id
   */
  ngOnInit() {
    this.activatedRoute.data.subscribe((data: Array<any>) => {
      const response = data['monuments'];
      this.typeOfView = response['type'];

      if (this.typeOfView === 'accommodations') {
        this.allObjects = response['response'][1]['objects'];
        this.getMonuments();

        this.trip = response['response'][0]['trip'];
        this.tripService.setTrip(this.trip);
        this.trip['trip_days'].forEach(day => {
          this.tripObjectArray.push(this.setTrackForDay(day));
        });

        this.mapService.subject.subscribe(answer => {
          if (answer === 'closeInfoToolbar') {
            this.monumentDesc.hide();
          }
        });
        this.selectedMonument = response['object'];
      }

      if (this.typeOfView === 'trip') {
        this.allObjects = response['response'][1]['objects'];
        this.getMonuments();

        this.trip = response['response'][0]['trip'];
        this.tripService.setTrip(this.trip);
        this.trip['trip_days'].forEach(day => {
          this.tripObjectArray.push(this.setTrackForDay(day));
        });


        this.mapService.subject.subscribe(answer => {
          if (answer === 'closeInfoToolbar') {
            this.monumentDesc.hide();
          }
        });
      }
    });

    this.renderRoadDay(this.indexOfDay);

    this.subscriptionDirection = this.directionService.directionSubject.subscribe(res => {
      if (res !== null) {
        this.renderRoadDay(res);
        this.allObjects = this.tripService.getDayMonuments(res);

      } else {
        this.renderRoadDay(this.indexOfDay);
        this.allObjects = this.tripService.getDayMonuments(this.indexOfDay);
      }
    });

    this.subscriptionDirection2 = this.directionService.directionSubjectWithOutHideObj.subscribe(res => {
      if (res !== null) {
        this.renderRoadDay(res);
      } else {
        this.renderRoadDay(this.indexOfDay);
      }
    });

    this.origin = this.tripObjectArray[this.indexOfDay].origin;
    this.destination = this.tripObjectArray[this.indexOfDay].destination;


    this.showValueObs();

    this.mapService.tripDaysObserver.next(this.tripService.getLengthDays());

    this.tripService.newTripBtnObs.next(false);

    this.mapService.groupMonuments.subscribe(res => {
      this.groupMonuments = res;
    });
  }

  ngAfterViewInit(): void {

  }

  /** centrowanie na trasie wycieczki  */
  public centerOn(res) {
    const tmpArray = this.tripService.getDayMonuments(res);
    if (Array.isArray(tmpArray) && tmpArray.length > 0) {
      const points = [];
      tmpArray.forEach(item => {
        points.push(item.coordinates[0]);
      });

      this.setMapOnPosition(this.ToFloat(tmpArray[0].coordinates[0].lat), this.ToFloat(tmpArray[0].coordinates[0].lng), 9, points);
    }
  }

  /** funcja renderuje trase przejazdu */
  public renderRoadDay(indexOfDay): void {
    this.indexOfDay = indexOfDay;
    const modeOfTransport = this.tripService.getDayMode(this.indexOfDay);
    this.monumentsArray = this.tripService.getDayMonuments(this.indexOfDay);
    const roadParam = this.directionService.prepareParam(this.monumentsArray);
    if (!!roadParam) {
      this.directionService.getRoadFromGoogleMaps(roadParam, modeOfTransport).subscribe(response => {
        // response = response.data;
        if (this.deviceService.getPlatform() === 'browser') {
          response = response.data;
        }
        if (response.status === 'OK') {
          const overview_polyline = response['routes'][0].overview_polyline;
          const decodePolyline = require('decode-google-map-polyline');
          this.routeArray = decodePolyline(overview_polyline.points);
          this.centerOn(this.routeArray);
          this.getIndexOfPolyLine(response);
          this.origin = this.tripService.getOriginDay(this.indexOfDay);
          this.destination = this.tripService.getDestinationDay(this.indexOfDay);
        } else {
          this.toast.subjectToast.next(ToastMaker.create({
            message: 'Wystąpił błąd podczas generowania mapy. Skontaktuj się z nami, aby wyjaśnić sytuację.',
            time: 4000,
            type: 'error'
          }));
        }
      });
    }
  }

  public getIndexOfPolyLine(response) {
    const tmpArray = [];
    for (let i = 0; i < this.monumentsArray.length; i++) {
      if (
        Array.isArray(this.monumentsArray)
        && this.monumentsArray[i].hasOwnProperty('coordinates')
        && this.monumentsArray[i].coordinates.length > 1) {
        tmpArray.push(this.monumentsArray[i].coordinates);
      }
    }

    const tmpIndexArray = this.mapService.getClosedPointFromDirection(this.routeArray, tmpArray);


    const filterLast = this.monumentsArray.filter((item, i) => {
      return (i + 1 === this.monumentsArray.length) && (item.coordinates.length > 1);
    });

    filterLast.forEach((item, i) => {
      this.routeArray = this.routeArray.concat(tmpArray[i]);
    });

    /** obiekt liniowy pierwszy */
    const filterFirst = tmpIndexArray.filter(item => {
      return item === 0;
    });

    filterFirst.forEach((item, i) => {
      const tmp = this.mapService.reverseArr(tmpArray[i]);
      this.routeArray = tmp.concat(this.routeArray);
    });


    const filterOther = tmpIndexArray.filter((item, i) => {
      return !((item === 0) || (item + 1 === this.routeArray.length)) &&
        !((i + 1 === this.monumentsArray.length) && (item.coordinates.length > 1));
    });


    filterOther.forEach((item, i) => {
      const indexEnd = this.mapService.getClosedPointFromDirectionEnd(this.routeArray, tmpArray[i]);
      const arrayBegin = this.routeArray.slice(0, item);
      const arrayEnd = this.routeArray.slice(indexEnd);
      this.routeArray = [...arrayBegin, ...tmpArray[i], ...arrayEnd];
    });

  }

  /** listener dla polozenia pineski na mapie */
  public dragEndOrigin(event: any, lastPosition: any): void {
    let closedMarkerCords;
    closedMarkerCords = this.mapService.findClosedMarkerCords(event.coords, lastPosition, this.allObjects);
    if (closedMarkerCords !== null) {
      if (this.ToFloat(this.origin.lat) === this.ToFloat(closedMarkerCords.lat) &&
        this.ToFloat(this.origin.lng) === this.ToFloat(closedMarkerCords.lng)) {
        // objekt ten sam
        this.origin.lng = this.ToFloat(this.origin.lng) + 0.0000000001;
        this.origin.lat = this.ToFloat(this.origin.lat) + 0.0000000001;
      } else {
        /** nowy obiekt */
        this.origin = closedMarkerCords;
        const object = this.mapService.getMonumentByCords(closedMarkerCords, this.allObjects);
        if (object) {
          let tmpArrayDay = this.tripService.getDayMonuments(this.indexOfDay);
          if (Array.isArray(tmpArrayDay) && tmpArrayDay.length > 0) {
            const monument_id = object.id_objects;
            const indexObject = this.monumentsArray.findIndex(obj => obj['id_objects'] === monument_id);
            if (indexObject !== -1) {
              tmpArrayDay = this.array_move(tmpArrayDay, indexObject, 0);
            } else {
              tmpArrayDay[0] = object;
              this.tripService.setDayMonuments(tmpArrayDay, this.indexOfDay);
            }
            this.renderRoadDay(this.indexOfDay);
          }
        }
      }
    } else {
      /** nocleg */
      this.origin.lng = this.ToFloat(this.origin.lng) + 0.0000001;
      this.origin.lat = this.ToFloat(this.origin.lat) + 0.0000001;
    }
    this.dayDetail.setStartWay();
  }

  /** listener dla polozenia pineski na mapie */
  public dragEndDestination(event: any, lastPosition: any): void {
    let closedMarkerCords;
    closedMarkerCords = this.mapService.findClosedMarkerCords(event.coords, lastPosition, this.allObjects);
    if (closedMarkerCords !== null) {

      if (this.ToFloat(this.destination.lat) === this.ToFloat(closedMarkerCords.lat) &&
        this.ToFloat(this.destination.lng) === this.ToFloat(closedMarkerCords.lng)) {
        // objekt ten sam
        this.destination.lng = this.ToFloat(lastPosition.lng) + 0.000001;
        this.destination.lat = this.ToFloat(lastPosition.lat) + 0.000001;

      } else {
        /** nowy obiekt */
        this.destination = closedMarkerCords;
        const object = this.mapService.getMonumentByCords(this.destination, this.allObjects);
        if (object) {
          const tmpArrayDay = this.tripService.getDayMonuments(this.indexOfDay);
          if (Array.isArray(tmpArrayDay) && tmpArrayDay.length > 0) {
            const monument_id = object.id_objects;
            const indexObject = this.monumentsArray.findIndex(obj => obj['id_objects'] === monument_id);
            if (indexObject !== -1) {
              this.tripService.setDayMonuments(this.array_move(tmpArrayDay, indexObject, tmpArrayDay.length - 1), this.indexOfDay);
            } else {
              tmpArrayDay[tmpArrayDay.length - 1] = object;
              this.tripService.setDayMonuments(tmpArrayDay, this.indexOfDay);
            }
            this.renderRoadDay(this.indexOfDay);
          }
        }
      }
    } else {
      /** nocleg */
      this.destination.lng = this.ToFloat(this.destination.lng) + 0.0000001;
      this.destination.lat = this.ToFloat(this.destination.lat) + 0.0000001;
    }
    this.dayDetail.setStartWay();
  }

  public array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }


  /** ustawia tracking dla poszczególnego dnia  */
  public setTrackForDay(day: DayOfTrip): Track {
    // API proszę zrobić mi wycieczke z wayPoints
    const dayArray = day['objects'];
    let origin = null, destination = null, waypoints = null;
    if (dayArray.length > 2) {
      origin = this.preparePin(0, dayArray);
      destination = this.preparePin(dayArray.length - 1, dayArray);
      waypoints = this.preparePoints();
    } else if (dayArray.length === 2) {
      origin = this.preparePin(0, dayArray);
      destination = this.preparePin(dayArray.length - 1, dayArray);
      waypoints = [];
    } else if (dayArray.length === 1) {
      origin = this.preparePin(0, dayArray);
      destination = this.preparePin(0, dayArray);
      waypoints = [];
    } else if (dayArray.length === 0) {
      // todo zapis do api
      destination = {lat: this.lat, lng: this.lng};
      origin = {lat: this.lat, lng: this.lng};
      waypoints = [];
    }
    const track: Track = {
      origin: origin,
      destination: destination,
      waypoint: waypoints,
    };

    return track;
  }

  /** ustawienie wayPointow dla mapy */
  public preparePoints(): Array<any> {
    const waypoints = [];
    const wayPointsProcess = this.tripObjectArray.slice(1, -1);
    wayPointsProcess.forEach(element => {
      if (element.hasOwnProperty('coordinates')) {
        const latFloat = this.ToFloat(element.coordinates[0].lat);
        const lngFloat = this.ToFloat(element.coordinates[0].lng);
        const waypoint = {
          location: {lat: latFloat, lng: lngFloat},
          stopover: false
        };
        waypoints.push(waypoint);
      }

    });

    return waypoints;
  }

  /** ustawia routing dla mapy start i stop */
  public preparePin(indexStart, array) {
    const pin = {
      lat: array[indexStart].coordinates[0].lat,
      lng: array[indexStart].coordinates[0].lng
    };

    /** przygotowanie do routingu */
    pin.lat = this.ToFloat(pin.lat);
    pin.lng = this.ToFloat(pin.lng);

    return pin;
  }


  /** pobieranie wszytkich obiektow na mapie */
  public getMonuments() {
    this.amountObjects = this.allObjects.length;
    this.allObjectsCopy = this.allObjects;
    this.mapService.setAllObjectArray(this.allObjects);
    this.localStorageService.setItem('Guide', 'allObjects', this.allObjects);
  }


  /**
   * funkcja zamienia na float
   */
  public ToFloat(input: string): number {
    return parseFloat(input);
  }


  /** funkcja uruchamiana po kliknieciu lokalizuj  */
  public location() {
    this.apiService.showLoader();
    if (navigator.geolocation && this.deviceService.getPlatform() === 'browser') {
      navigator.geolocation.getCurrentPosition((cords) => {
        this.showPosition(cords);
      }, (error) => {
        this.showError(error);
      });
    }

    if (this.deviceService.getPlatform() !== 'browser') {
      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition((cords) => {
          this.showPosition(cords);
        }, (error) => {
          this.showError(error);
        });
      }
    }

  }

  public showPosition(position) {
    this.setMapOnPosition(position.coords.latitude, position.coords.longitude, 12);
    this.apiService.hideLoader();
  }

  public showError(error) {
    let text = 'error';
    switch (error.code) {
      case error.PERMISSION_DENIED:
        text = 'Nie wyraziłeś zgody na pobranie lokalizacji.';
        break;
      case error.POSITION_UNAVAILABLE:
        text = 'Nie można określić Twojej lokalizacji.';
        break;
      case error.TIMEOUT:
        text = 'Przekroczono czas żądania. Skontaktuj się z nami, aby wyjaśnić sytuację.';
        break;
      case error.UNKNOWN_ERROR:
        text = 'Wystąpił nieznany błąd. Skontaktuj się z nami, aby wyjaśnić sytuację.';
        break;
    }
    this.toast.subjectToast.next(ToastMaker.create({message: text, time: 4000, type: 'error'}));
    this.apiService.hideLoader();
  }


  /** zapisanie referecji do mapy,
   *  init geocodera */
  public mapReady(map) {
    this.map = map;
    this.mapService.initGeocoder();
    if (this.typeOfView === 'accommodations') {
      this.showMonumentDetail(this.selectedMonument);
    } else {
      if (this.tripService.getLengthDays() === 1 && this.tripService.getDayMonuments(0).length === 0) {
        this.appToolbar.open();
        this.location();
      } else {
        this.tripInfoCom.open();
      }
    }
  }

  /** ustawienie mapy na żadanej pozycji */
  public setMapOnPosition(lat, lng, zoom = 16, points?) {
    if (typeof points !== 'undefined') {
      const bounds: LatLngBounds = new google.maps.LatLngBounds();
      for (const mm of points) {
        bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
      }
      this.map.fitBounds(bounds, 20);
    } else {
      if (lat !== null && lng !== null) {
        this.map.zoom = zoom;
        this.mapService.groupMonuments.next(false);
        this.map.setCenter({lat: lat, lng: lng});
      }
    }
  }

  /** uruchamia funkcję z geocodujaca miasto */
  public geoCode(city) {
    this.mapService.getGeocoding(city.city, city.zip_code).subscribe(response => {
      this.setMapOnPosition(response.lat(), response.lng(), 12);
    });
  }

  /** listener dla danych przejomowanych wyszukiwarki */
  public cordsFromSearch(data) {
    this.setMapOnPosition(this.ToFloat(data.lat), this.ToFloat(data.lng), 15);
  }

  /** fukcja ustawia automatyczne wyszukiwanie */
  public setAutomaticSearch(val: boolean) {
    this.toggleAutomaticSearch = val;
  }

  /** lister na zmianę bounds na mapie,
   *  dodatkowo uruchamia wyszukiwarkę  */
  public boundsChange(event) {
    this.mapBounds = this.mapService.getMapBounds(this.map);
    this.subjectBouds.next(this.mapBounds);
  }

  public showValueObs() {
    this.subjectBouds.pipe(debounceTime(500)).subscribe(params => {
      if (this.appToolbar.toggleFlagBoudaries && this.appToolbar.toggleFlag) {
        this.appToolbar.showValue(false);
      }
    });

  }

  /** ustawia kolor dla obiektu liniowego */
  public getLineColor(color): string {
    return (color === null ? 'red' : '#' + color);
  }

  /** pobiera dane i wysyla je do bocznego komponentu z lewej */
  public monumentDetail(id: string, monumentRef, pin) {
    this.selectedPin = id;
    this.mapService.toggleMenuItemObs.next({state: true, action: 'infoMonument'});
    this.apiService.getMonument(id).subscribe(response => {
      this.clickedMonument = response['data']['object'];
      this.monumentDesc.writeData(this.clickedMonument);
    });
  }

  public showMonumentDetail(id: string) {
    this.selectedPin = id;
    this.mapService.toggleMenuItemObs.next({state: true, action: 'infoMonument'});
    this.apiService.getMonument(id).subscribe(response => {
      this.clickedMonument = response['data']['object'];
      this.monumentDesc.writeData(this.clickedMonument);
      this.cordsFromSearch(response['data']['object']['coordinates'][0]);
    });
  }

  /** pokazuje obiekty z dnia wycieczki */
  public showOnlyObjects(event) {
    const arrayObj = event['arrayObj'];
    this.showId(arrayObj);
    if (event['fromTripInfo']) {
      this.renderRoadDay(0);
    }
  }

  /** pokazuje wyszukane obiekty z dnia wycieczki */
  public searchedObjects(event) {
    if (!this.dayDetail.toggleFlag && !this.tripInfoCom.toggleFlag) {
      this.showId(event);
    }
  }

  public showId(tmpArray) {
    let array = [];
    for (let i = 0; i < tmpArray.length; i++) {
      array = array.concat(this.allObjectsCopy.filter(item => {
        return tmpArray[i] === item.id_objects;
      }));
    }
    this.allObjects = array;

  }

  /** pokazuje ulubione obiekty */
  public favoriteObjects(event: Array<Monument>) {
    this.allObjects = event;
  }

  /** centrowanie na danym monumencie */
  public centerMapOnObject(event) {
    this.setMapOnPosition(this.ToFloat(event.lat), this.ToFloat(event.lng));
    this.monumentDesc.hide();
  }

  public selectPin(id) {
    this.selectedPin = id;
    this.apiService.getMonument(id).subscribe(response => {
      this.cordsFromSearch(response['data']['object']['coordinates'][0]);
    });
  }

  public writePrintTrip(event) {
    if (event) {
      this.printTrip.writeData();
    }
  }


  public start_stop_Click(coords, isAccommodation) {
    const object = this.mapService.getMonumentByCords(coords, this.allObjectsCopy);
    this.monumentDetail(object.id_objects.toString(), null, null);
  }

  public showMessage(text) {
    this.toast.subjectToast.next(ToastMaker.create({message: text, time: 4000, type: 'error'}));
  }
}
