import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {DayOfTrip, Monument, ToastMaker, Trip} from '../models/models.map';
import {ApiService} from 'src/app/shared/api.service';
import {LocalStorageService} from '../../shared/local-storage.service';
import {Router} from '@angular/router';
import {ToastService} from '../../shared/toast.service';

@Injectable()
export class TripService {

  constructor(
    public apiService: ApiService,
    public localStorageService: LocalStorageService,
    public router: Router,
    public toast: ToastService
  ) {
  }

  private jsonTrip: any;

  /** behaviour subject wybranego dnia w leftbar wycieczki */
  public indexOfDay = new BehaviorSubject(0);

  public tripNameObs = new Subject<string>();
  public tripURLObs = new Subject<string>();
  public iframeUrlObs = new Subject<string>();
  public outOfDayObs = new Subject<boolean>();
  public newTripBtnObs = new Subject<boolean>();
  public isSavedTripObs = new Subject<boolean>();
  public isSavedForExportObs = new Subject<boolean>();
  public exportTripObs = new Subject<boolean>();

  public modeTripForAll = 0;


  public getTrip(): Trip {
    return this.jsonTrip;
  }

  public getTripId(): any {
    if (this.jsonTrip.hasOwnProperty('id_trips')) {
      return this.jsonTrip['id_trips'];
    } else {
      return false;
    }
  }

  public getDays(): Array<DayOfTrip> {
    if (this.jsonTrip.hasOwnProperty('trip_days')) {
      return this.jsonTrip['trip_days'];
    } else {
      return [];
    }

  }


  public getLengthDays() {
    return this.getDays().length;
  }

  public getDay(id: number): any {
    if (this.jsonTrip.hasOwnProperty('trip_days') && Array.isArray(this.jsonTrip['trip_days'])) {
      return this.jsonTrip['trip_days'][id];
    } else {
      return null;
    }

  }

  public getDayMode(id: number): number {
    const day: DayOfTrip = this.getDay(id);
    const defaultTyp = 0;
    if (day && day.hasOwnProperty('mode_of_transportation')) {
      if (day.mode_of_transportation !== null) {
        return day.mode_of_transportation;
      } else {
        return defaultTyp;
      }
    } else {
      return defaultTyp;
    }
  }

  public getDayMonuments(id: number): Array<Monument> {
    const day: DayOfTrip = this.getDay(id);
    // objects
    if (day && day.hasOwnProperty('objects')) {
      return day['objects'];
    }

  }

  public getOriginDay(id: number): any {
    const tmp = this.getDayMonuments(id);
    if (Array.isArray(tmp) && tmp.length > 1) {
      return tmp[0].coordinates[0];
    } else {
      return null;
    }
  }

  public getDestinationDay(id: number): any {
    const tmp = this.getDayMonuments(id);
    if (Array.isArray(tmp) && tmp.length > 1) {
      return tmp[tmp.length - 1].coordinates[0];
    } else {
      return null;
    }
  }

  public getDayMonument(idDay: number, idMonument: number): Monument {
    const day: DayOfTrip = this.getDay(idDay);
    // objects
    if (day && day.hasOwnProperty('objects')) {
      return day['objects'][idMonument];
    }
  }

  public getDayStart(index): string {
    const day = this.getDay(index);
    if (!!day) {
      return this.jsonTrip['trip_days'][index]['time_start'];
    }
  }

  public getStartTrip(): string {
    if (this.getTrip().hasOwnProperty('trip_start')) {
      return this.jsonTrip['trip_start'];
    } else {
      return null;
    }

  }

  /** sprawdza czy ostatni monument jest noclegiem */
  public checkLastMonument(index: number): Monument {
    const tmpArray = this.getDayMonuments(index);

    if (tmpArray.length === 0) {
      return null;
    }
    const tmpObj = this.getDayMonument(index, tmpArray.length - 1);
    const isAccomondation = tmpObj.accommodation;

    if (!!isAccomondation) {
      return tmpObj;
    }

    return null;
  }


  /** sprawdza czy ostatni monument jest noclegiem */
  public checkFirstMonument(index: number): Monument {
    const tmpArray = this.getDayMonuments(index);

    if (tmpArray.length === 0) {
      return null;
    }
    const tmpObj = this.getDayMonument(index, 0);
    const isAccomondation = tmpObj.accommodation;

    if (!!isAccomondation) {
      return tmpObj;
    }

    return null;
  }


  public setTrip(inputTrip): any {
    // save Api
    this.jsonTrip = inputTrip;
  }

  public setTripId(id) {
    if (this.jsonTrip.hasOwnProperty('id_trips')) {
      this.jsonTrip['id_trips'] = id;
    }
  }

  public setDays(newDaysArray: Array<any>) {
    // save Api
    if (Array.isArray(newDaysArray)) {
      this.jsonTrip['trip_days'] = newDaysArray;
    }
  }

  public setDayMode(id: number): void {
    // save  Api
    const mode = this.jsonTrip['trip_days'][id].mode_of_transportation + 1;
    const day: DayOfTrip = this.getDay(id);
    if (day && day.hasOwnProperty('mode_of_transportation')) {
      this.jsonTrip['trip_days'][id].mode_of_transportation = mode % 3;
    }
  }

  public setModeForAll(mode: number): void {
    // save Api
    const tmpTripDays = this.jsonTrip['trip_days'];

    tmpTripDays.forEach(day => {
      day.mode_of_transportation = mode;
    });

    this.jsonTrip['trip_days'] = tmpTripDays;
  }

  public setDayMonuments(input: Array<Monument>, index): void {
    if (!!this.jsonTrip['trip_days'][index]['objects']) {
      this.jsonTrip['trip_days'][index]['objects'] = input;
    }
  }

  public setDayStart(index, time) {
    const day = this.getDay(index);
    if (!!day) {
      this.jsonTrip['trip_days'][index]['time_start'] = time;
    }
  }

  public setStartTrip(date: string): void {
    this.jsonTrip['trip_start'] = date;
  }

  public addNewDay() {
    // save Api
    const sampleDay = {
      day: null,
      id_trip: null,
      id_trip_days: null,
      mode_of_transportation: this.modeTripForAll,
      objects: [],
      time_start: '8:00',
      time_tour: '02:00:00'
    };

    if (Array.isArray(this.jsonTrip['trip_days']) && this.jsonTrip['trip_days'].length < 9) {
      const tmpArrayDays = this.jsonTrip['trip_days'];
      this.setDays(tmpArrayDays.push(sampleDay));
    }
  }

  public addNewDayWithAccomondation(monumet) {
    const sampleDay = {
      day: null,
      id_trip: null,
      id_trip_days: null,
      mode_of_transportation: this.modeTripForAll,
      objects: [monumet],
      time_start: '8:00',
      time_tour: '02:00:00'
    };

    if (Array.isArray(this.jsonTrip['trip_days']) && this.jsonTrip['trip_days'].length < 9) {
      const tmpArrayDays = this.jsonTrip['trip_days'];
      this.setDays(tmpArrayDays.push(sampleDay));
    }
  }

  public checkIfSlipingLast(index) {
    const tmpdayMon = this.getDayMonuments(index);
    return (Array.isArray(tmpdayMon) && tmpdayMon.length > 0 && tmpdayMon[tmpdayMon.length - 1].accommodation === 1);
  }

  public saveKeyKK(id, key) {

    if (!this.localStorageService.hasItem('Guide', id.toString())) {
      this.localStorageService.setItem('Guide', id.toString(), key.toString());
    }

  }

  public getKeyKK(id) {
    return this.localStorageService.getItem('Guide', id);
  }

  public checkKeyKK(id) {
    return this.localStorageService.hasItem('Guide', id);
  }

  public generateJsonToSave(json) {

    let jsonRaw = json;
    jsonRaw['save_key'] = (this.checkKeyKK(this.getTripId()))
      ? this.getKeyKK(this.getTripId()).toString().replace(/"/g, '')
      : null;
    // delete jsonRaw['id_trips'];
    delete jsonRaw['id_device'];
    delete jsonRaw['image'];
    delete jsonRaw['screenshot'];
    delete jsonRaw['www'];
    delete jsonRaw['description'];
    delete jsonRaw ['created_by'];
    delete jsonRaw ['create_day'];
    delete jsonRaw ['views'];
    delete jsonRaw ['public'];
    delete jsonRaw ['recommended'];
    delete jsonRaw ['recaptchaSave'];
    jsonRaw ['days'] = this.getLengthDays();

    jsonRaw.trip_days = jsonRaw.trip_days.map((item, i) => {
      item['day'] = i;
      item['id_trip'] = this.getTripId();
      item['objects'] = item.objects.map(object => {
        return {id_objects: object.id_objects};
      });

      return item;
    });

    return jsonRaw;
  }

  /** zapis wycieczki */

  public saveTrip(newTrip = null) {
    /** Nowa wycieczka*/
    if (!!newTrip) {
      const sub = this.apiService.editTrip(this.getTripId(), this.generateJsonToSave(newTrip)).subscribe(res => {
        /** Sprawdzenie czy już była dodana jak nie to zapisuje w localstorage*/
        this.saveKeyKK(res.data['id_trip'], res.data['save_key']);

        /** Ustawienie id nowej wycieczki*/
        this.setTripId(res.data['id_trip']);
        this.apiService.getTripDetails(res.data['id_trip']).subscribe(response => {
          const trip = response.data.trip;
          this.setTrip(trip);
          this.tripNameObs.next(trip.name);
          this.tripURLObs.next(this.apiService.baseUrl + '#/map/' + trip.id_trips);
          this.iframeUrlObs.next(this.apiService.apiMainUrl + '8/multimedia-atlas.html?hash=' + trip.id_trips);
          this.router.navigate(['map', res.data['id_trip']]);
          this.isSavedTripObs.next(true);
          this.isSavedForExportObs.next(true);
          this.toast.subjectToast.next(ToastMaker.create({message: 'Wycieczka została zapisana!', time: 4000, type: 'success'}));
          this.apiService.hideLoader();
        });
      });
      return sub;
    } else {
      const sub = this.apiService.editTrip(this.getTripId(), this.generateJsonToSave(this.getTrip())).subscribe(res => {
        /** Sprawdzenie czy już była dodana jak nie to zapisuje w localstorage*/
        this.saveKeyKK(res.data['id_trip'], res.data['save_key']);

        this.apiService.getTripDetails(this.getTripId()).subscribe(response => {
          const trip = response.data.trip;
          this.setTrip(trip);
          this.tripNameObs.next(trip.name);
          this.tripURLObs.next(this.apiService.baseUrl + '#/map/' + trip.id_trips);
          this.iframeUrlObs.next(this.apiService.apiMainUrl + '8/multimedia-atlas.html?hash=' + trip.id_trips);
          this.isSavedTripObs.next(true);
          this.isSavedForExportObs.next(true);
        });
      });

      return sub;
    }
  }

}
