import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {TripService} from '../services/trip.service';
import {DistanceDuration, ToastMaker, Trip} from '../models/models.map';
import {formatDate} from '@angular/common';
import {ToolSetService} from '../services/tool-set.service';
import {DirectionService} from '../services/direction.service';
import {Subject, Subscription} from 'rxjs';
import {ApiService} from '../../shared/api.service';
import {debounceTime} from 'rxjs/operators';
import {ToastService} from '../../shared/toast.service';

@Component({
  selector: 'app-print-trip',
  templateUrl: './print-trip.component.html',
  styleUrls: ['./print-trip.component.scss']
})
export class PrintTripComponent implements OnInit, OnDestroy {

  public hasPrintTripData: any = false;
  public trip: Trip;

  public timeOfSightseeing = '01:00:00';
  /** Domyślny czas zwiedzania*/
  public roads: Array<any> = [];
  public roadsInfo: Array<any> = [];

  public subjectPrint = new Subject();
  public subscriptionPrint = new Subscription();
  public counterPrint = 0;

  constructor(
    public directionService: DirectionService,
    public tripService: TripService,
    public toolSetService: ToolSetService,
    public apiService: ApiService,
    public toast: ToastService
  ) {
  }

  ngOnInit() {
    this.subjectPrint.subscribe(res => {

      this.counterPrint += 1;
      if (this.counterPrint === res['daysMax']) {
        setTimeout(() => {
          this.apiService.hideLoader();
        }, res['timeout']);

        setTimeout(() => {
          this.apiService.hideLoader();
          this.toolSetService.printSubject.next({print: true});
          document.getElementsByClassName('map-container')[0].classList.remove('print-hidden');
        }, res['timeout'] + 300);
      }

    });
  }

  ngOnDestroy() {
    this.subscriptionPrint.unsubscribe();
  }

  public writeData() {
    document.getElementsByClassName('map-container')[0].classList.add('print-hidden');
    this.counterPrint = 0;
    this.trip = this.tripService.getTrip();
    let maxTime = 500;
    let error = false;
    for (let i = 0; i < this.trip.trip_days.length; i++) {
      let currentDay = this.trip.trip_days[i];
      const roadParam = this.directionService.prepareParam(currentDay.objects);
      if (roadParam !== null) {
        maxTime += roadParam.waypoints.length * 500;
        this.directionService.getRoadFromGoogleMaps(roadParam, currentDay.mode_of_transportation).subscribe(response => {
          response = response.data;
          if (response.status === 'OK') {
            this.roads[i] = response['routes'][0].legs[0];
            this.roadsInfo[i] = this.setRoadInfo(response['routes'][0].legs[0]);
            const tmpData = this.setTimeTable(currentDay.time_start, currentDay.objects, this.roadsInfo[i]);
            this.roadsInfo[i]['start'] = tmpData['start'];
            this.roadsInfo[i]['stop'] = tmpData['stop'];
            this.hasPrintTripData = true;

          } else {
            this.toast.subjectToast.next(ToastMaker.create({message: 'Nie można było przygotować wydruku', time: 4000, type: 'error'}));
            this.apiService.hideLoader();
            error = true;
          }
        }).add(() => {
          if (!error) {
            this.subjectPrint.next({index: i, daysMax: this.trip.trip_days.length, timeout: maxTime});
          }
        });
      } else {
        if (i === 0 && this.trip.trip_days.length === 1) {
          this.hasPrintTripData = true;
        }
        if (!error) {
          this.subjectPrint.next({index: i, daysMax: this.trip.trip_days.length, timeout: maxTime});
        }
      }
    }
  }

  public setRoadInfo(roadInfo) {
    let infoAboutWay = [];
    const tmpWaypoints = roadInfo.via_waypoint;
    const tmpSteps = roadInfo.steps;
    const indexOfWayPoint = [0];

    for (let i = 0; i < tmpWaypoints.length; i++) {
      indexOfWayPoint.push(tmpWaypoints[i].step_index);
    }
    indexOfWayPoint.push(tmpSteps.length);

    for (let j = 0; j < indexOfWayPoint.length - 1; j++) {
      const nextJ = j + 1;
      const tmpWay = tmpSteps.slice(indexOfWayPoint[j], indexOfWayPoint[nextJ]);
      let distance = 0;
      let duration = 0;
      for (let i = 0; i < tmpWay.length; i++) {
        distance += tmpWay[i].distance.value;
        duration += tmpWay[i].duration.value;
      }

      const tmpJson: DistanceDuration = {distance: this.getFormattedDistance(distance), duration: this.secondsToHms(duration)};
      infoAboutWay.push(tmpJson);
    }

    return infoAboutWay;
  }

  public setTimeTable(startTime, monuments, roadInfo) {
    let arrayStart = [];
    let arrayStop = [];
    let curr = new Date('2019-01-01T' + startTime + ':00Z').getTime() / 1000;

    if (roadInfo.length > 0) {

      for (let i = 1; i < monuments.length; i++) {
        const tmpDate = new Date(monuments[i - 1].time_tour === null ? '2019-01-01T00:00:00Z' : '2019-01-01T' + monuments[i - 1].time_tour + 'Z').getTime() / 1000;
        const diff = tmpDate - new Date('2019-01-01T00:00:00Z').getTime() / 1000;
        curr = curr + diff;

        arrayStart.push(this.setTime((Math.floor(curr / 3600) % 24).toString()) + ':'
          + this.setTime(((curr / 60) % 60).toString()));

        const tmpList = roadInfo[i - 1]['duration'].replace('h', '').replace('min', '').replace('.', '').split(' ');

        const tmpNextDate = (tmpList.length > 1) ?
          new Date('2019-01-01T' + this.setTime(tmpList[0]) + ':' + this.setTime(tmpList[1]) + ':00Z').getTime() / 1000 :
          new Date('2019-01-01T00:' + this.setTime(tmpList[0]) + ':00Z').getTime() / 1000;
        const diff2 = tmpNextDate - new Date('2019-01-01T00:00:00Z').getTime() / 1000;

        curr = curr + diff2;
        arrayStop.push(this.setTime((Math.floor(curr / 3600) % 24).toString()) + ':'
          + this.setTime(((curr / 60) % 60).toString()));

      }
    }

    return {'start': arrayStart, 'stop': arrayStop};
  }

  public setTime(input: string): string {
    return (input.length > 1) ? input : '0' + input;
  }

  public secondsToHms(d) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = d % 60;

    return this.getFormattedTime(h + ':' + m + ':' + s);
  }

  public getFormattedTime(dataTime) {
    if (dataTime === null) {
      dataTime = this.timeOfSightseeing;
    }

    let html = '';
    let time = dataTime.split(':');
    for (let i = 0; i < 2; i++) {
      while (time[i].charAt(0) === '0') {
        time[i] = time[i].substr(1);
      }
    }

    if (time[0] !== '') {
      /** godziny */
      html += time[0] + 'h';

      /** minuty */
      if (time[1] !== '') {
        html += ' ' + time[1] + 'min.';
      }
    } else {
      if (time[1] !== '') {
        /** tylko minuty */
        html += time[1] + 'min.';
      } else {
        if (time[2] !== '') {
          html += '1min.'; // zabezpieczenia jak czas przebycia trwa kilka sekund
        } else {
          html += '1h';
        }
      }
    }

    return html;
  }

  public setDay(dayindex) {
    return formatDate(this.toolSetService.getNextDay(dayindex), 'dd.MM.yyyy', 'en');
  }

  public getNameDay(dayindex) {
    const days = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
    const nextDay = this.toolSetService.getNextDay(dayindex);
    const nameDay = nextDay.getDay();
    return days [nameDay];
  }

  public getFormattedDistance(distance) {
    let html = '';
    if (distance === null) {
      html += '0km';
    } else {
      html += Math.round(distance / 10) / 100 + ' km';
    }

    return html;
  }

}
