import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, concat, forkJoin } from 'rxjs';
import { ApiService } from '../../shared/api.service';
import { ResponseApi } from '../../shared/models/api-models';
import { map } from 'rxjs/operators';
import { LocaleService } from '../../share/locale.service';

/**
 *  Route resolvers służy dostarczeniu danych do Routa,
 *  przed aktywacją Routa, a jeszcze prościej mówiąc,
 *  zmuszamy naszą aplikację, aby pokazał widok dopiero jak dane zostaną dostarczone.
 */

@Injectable({
  providedIn: 'root'
})
export class MapResolve implements Resolve<any> {

  /**
   * funkcja zwraca dane w postaci obiektu lub observabla
   */
  resolve(route: ActivatedRouteSnapshot, stare: RouterStateSnapshot): Observable<any> {

    const regExprIsNumber = new RegExp('^\\w{8}');
    const param = route.url['1'].path;
    // tworzenie nowej wycieczki
    if (param === 'create') {
      return this.api.getMonuments().pipe(
        map (response  => {
          const data = response['data'];
          data['type'] = 'objects';
          return data;
        }),
      );
    }
    // wyświetlenie noclegów obok obiektu
    if (param === 'accommodations') {
      const objectId = parseInt(route.url['2'].path, 10);
      const tripDetails = this.api.getTripDetails('new_trip');
      const getMonument = this.api.getMonumentsOnMap().pipe(
        map (response  => {
          response['data']['objects'] = response['data']['objects'].filter((item, i) => {
            return (item.accommodation === 1 || item.id_objects === objectId);
          });
          return response;
        })
      );

      // pobranie kategorii i zapisanie do localStorage
      this.api.getCategories().subscribe(
        res => {
          this.loacalStorage.setItem('Guide', 'Categories', null );
          this.loacalStorage.setItem('Guide', 'Categories', res.data );
        }
      );

      return forkJoin([tripDetails, getMonument]).pipe(
        map(res => {
          const data = {};
          res[0] = res[0].data;
          res[1] = res[1].data;
          data['response'] = res;
          data['type'] = 'accommodations';
          data['object'] = objectId;
          return data;
        })
      );
    }
    // edycja wycieczki
    if (param.match(regExprIsNumber)) {
      const tripDetails = this.api.getTripDetails(param);
      const getMonument = this.api.getMonumentsOnMap();

      // pobranie kategorii i zapisanie do localStorage
      this.api.getCategories().subscribe(
        res => {
          this.loacalStorage.setItem('Guide', 'Categories', null );
          this.loacalStorage.setItem('Guide', 'Categories', res.data );
        }
      );

      return forkJoin([tripDetails, getMonument]).pipe(
        map(res => {
          const data = {};
          res[0] = res[0].data;
          res[1] = res[1].data;
          data['response'] = res;
          data['type'] = 'trip';
          return data;
        })
      );
    }
  }

  constructor(
    private api: ApiService,
    private loacalStorage: LocaleService
  ) { }

}
