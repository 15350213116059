import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';
import { AgmOverlays } from 'agm-overlays';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmDirectionModule } from 'agm-direction';
import { ObjectMenuComponent } from './object-menu/object-menu.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MapCreateTripComponent } from './map-create-trip/map-create-trip.component';
import { MapPinComponent } from './map-pin/map-pin.component';
import { TripInfoComponent } from './trip-info/trip-info.component';
import { DayDetailsComponent } from './day-details/day-details.component';
import { ObjectFormComponent } from './object-form/object-form.component';
import { SaveFormComponent } from './save-form/save-form.component';
import { DayAvatarComponent } from './trip-info/day-avatar/day-avatar.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgDatepickerModule } from 'ng2-datepicker';
import { FavoriteComponent } from './favorite/favorite.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { DirectionService } from './services/direction.service';
import { ShareModule } from '@ngx-share/core';
import { PrintTripComponent } from './print-trip/print-trip.component';
import { HttpClientModule } from '@angular/common/http';
import { TripService } from './services/trip.service';
import { DomChangeDirective } from './object-menu/directive/dom-change.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AgmOverlays,
    AgmJsMarkerClustererModule,
    AgmDirectionModule,
    SlideshowModule,
    NgxPaginationModule,
    DpDatePickerModule,
    NgDatepickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBWAAdx8SEhmixy_-7DakQF2ipLy3WJlw8'
    }),
    ReactiveFormsModule,
    DragDropModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    ShareModule.forRoot()
  ],
  declarations: [
    MapComponent,
    ObjectMenuComponent,
    ToolbarComponent,
    MapCreateTripComponent,
    MapPinComponent,
    TripInfoComponent,
    DayDetailsComponent,
    DayAvatarComponent,
    DayDetailsComponent,
    ObjectFormComponent,
    SaveFormComponent,
    FavoriteComponent,
    PrintTripComponent,
    DomChangeDirective
  ],
  providers: [
    TripService
  ]
})
export class MapsModule { }
