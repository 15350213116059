import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor() {
  }

  /**
   * funkcja, która filtruje obiety
   *   */

  public closeCheckBox = new Subject<any>();


  public filterCords(monumentCords, mapCords): boolean {

    const N = mapCords.nw.lat;
    const W = mapCords.nw.lng;

    const S = mapCords.se.lat;
    const E = mapCords.se.lng;

    const valLat = monumentCords.lat;
    const valLng = monumentCords.lng;

    return S < valLat && N > valLat && W < valLng && E > valLng;
  }
}
