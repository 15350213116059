import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {ResponseApi} from './models/api-models';
import {ConfigModel} from './config/config';
import {Trip} from '../map/models/models.map';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public apiUrl = ConfigModel.getApiUrl();
  public apiMainUrl = ConfigModel.getapiMainUrl();
  public baseUrl = ConfigModel.getBaseUrl();

  public pageObs = new Subject<any>();
  public showLoaderObs = new Subject<any>();

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   *  funkcja przygotowuje dane do wysłania metodą post
   *  zamienia dane z JSON do FormData, poniewaz skycms
   *  tak wymaga
   */
  public preprocessingData(data: any): FormData {
    const sendData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sendData.append(key, data[key]);
      }
    }
    return sendData;
  }

  /**
   *  funkcja przygotowuje dane do wysłania metodą post
   *  zamienia dane z JSON do URLSearchParams, poniewaz skycms
   *  tak wymaga
   */
  public preprocessingDataIntoUrlEncoded(data: any): URLSearchParams {
    const sendData = new URLSearchParams();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sendData.set(key.toString(), data[key]);
      }
    }
    return sendData;
  }

  public showLoader() {
    this.showLoaderObs.next(true);
  }

  public hideLoader() {
    this.showLoaderObs.next(false);
  }

  /**
   * pobiera momunety
   */
  public getMonuments(): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'objects/list');
  }

  public getMonumentsOnMap(): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'objects/list/map');
  }

  /**
   * pobiera wycieczki
   */

  public getTrips(): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'trips/list/1');
  }

  /** Polecane wycieczki*/
  public getRecommendedTrips(): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'trips/recommended/1');
  }

  /** Podobne wycieczki*/
  public getSimilaryTrips(id: string): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'trip/similar/' + id);
  }

  /**
   * pobiera szczegóły wycieczek
   */
  public getTripDetails(id: string): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'trip/trip/' + id);
  }

  /** kopiuje wycieczke */
  public copyTrip(id: string): Observable<ResponseApi> {
    return this.http.post<ResponseApi>(this.apiUrl + 'device/trip/copy/' + id, null);
  }

  /** edycja wycieczki  */

  public editTrip(id: string, trip: any): Observable<ResponseApi> {
    const tripSend = this.preprocessingData({'trip': JSON.stringify(trip)});
    return this.http.post<ResponseApi>(this.apiUrl + 'device/trip/edit/' + id, tripSend);
  }

  /** create trip */

  public createTrip(): Observable<ResponseApi> {
    return this.http.put<ResponseApi>(this.apiUrl + 'device/trip/add', null);
  }

  /**
   * pobiera poszczególny monument
   */
  public getMonument(id: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'object/' + id);
  }

  /** Dodaje licznik wyświetleń */
  public addViews(id: string): Observable<ResponseApi> {
    return this.http.post<ResponseApi>(this.apiUrl + 'trip/views/' + id, null);
  }


  /** Dodaje licznik wyświetleń */
  public getCategories(): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'categories/tree');
  }

  /** Pobiera dane z wyszukiwania  */
  public getSearchedObjects(name: string, city: string, body: HttpParams): Observable<ResponseApi> {
    city = ((city.trim() === '') ? 'null' : city);
    name = ((name.trim() === '') ? 'null' : name);
    return this.http.get<ResponseApi>(this.apiUrl + 'objects/search' + '/' + name + '/' + city, {params: body});
  }


  /** pobiera nazwy miast z api, kod pocztowy  */

  public getCities() {
    return this.http.get<ResponseApi>(this.apiUrl + 'objects/localizations');
  }

  /** pobieranie KML dla trip */
  public getTripKML(trip_id: string): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'trip/export/kml/' + trip_id);
  }

  /** pobieranie url KML dla day */
  public getTripKMLUrl(trip_id: string, day_id: string = null): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'trip/export/url/kml/' + trip_id + (day_id !== null ? '/' + day_id : ''));
  }

  /**pobieranie GPX dla trip */
  public getTripGPX(trip_id: string): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'trip/export/gpx/' + trip_id);

  }

  /**pobieranie url GPX dla trip */
  public getTripGPXUrl(trip_id: string, day_id: string = null): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'trip/export/url/gpx/' + trip_id + (day_id !== null ? '/' + day_id : ''));

  }

  /** pobiera page według id */
  public getPage(id: string): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'page/view/' + id);
  }

  /** pobranie ulubionych z api*/

  public getFavoriteObjects(): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(this.apiUrl + 'device/favorites');
  }

  /** dodawanie do ulubionych*/
  public addFavorite(id_object: string): Observable<ResponseApi> {
    return this.http.put<ResponseApi>(this.apiUrl + 'device/favorites/add/' + id_object, null);
  }

  /** usuwanie z ulubionych*/
  public removeFavorite(id_object): Observable<ResponseApi> {
    return this.http.put<ResponseApi>(this.apiUrl + 'device/favorites/remove/' + id_object, null);
  }

  /** udostępnianie przez e-mail*/
  public shareByEmail(trip_id: string, sendData): Observable<ResponseApi> {
    const data = this.preprocessingData({'data': JSON.stringify(sendData)});
    return this.http.post<ResponseApi>(this.apiUrl + 'device/trip/share/' + trip_id, data);
  }

  /** zgłaszanie do opublikowania*/
  public publishTrip(trip_id: string, sendData): Observable<ResponseApi> {
    const data = this.preprocessingData({'data': JSON.stringify(sendData)});
    return this.http.post<ResponseApi>(this.apiUrl + 'device/trip/publish/' + trip_id, data);
  }

  /** zmiany w obiekcie */
  public sendChangedObject(id_object, formData, files): Observable<ResponseApi> {
    const sendData = this.preprocessingData({'data': JSON.stringify(formData)});
    for (let i = 0; i < files.length; i++) {
      sendData.append('images[]', files[i]);
    }
    return this.http.post<ResponseApi>(this.apiUrl + 'device/object/suggestChange/' + id_object, sendData);
  }
}
