import {Component, OnInit, Renderer2, ViewChild, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {MapService} from '../services/map.service';
import {TripService} from '../services/trip.service';
import {ToolSetService} from '../services/tool-set.service';
import {DatePickerComponent} from 'ng2-date-picker';
import {formatDate} from '@angular/common';
import {DirectionService} from '../services/direction.service';
import {Monument, ToastMaker} from '../models/models.map';
import {DayAvatarComponent} from './day-avatar/day-avatar.component';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {LocalStorageService} from 'src/app/shared/local-storage.service';
import {ToastService} from '../../shared/toast.service';

@Component({
  selector: 'app-trip-info',
  templateUrl: './trip-info.component.html',
  styleUrls: ['./trip-info.component.scss']
})
export class TripInfoComponent implements OnInit {

  @ViewChild('navLeft') navLeft;

  @Output() renderDay = new EventEmitter<number>();
  @Output() showOnlyObjects = new EventEmitter<any>();

  public iconsBaseUrl = 'assets/images/';
  public daysArray;
  public toggleDataPicker = false;
  public subscription = new Subscription();

  public toggleFlag = false;
  public trip: any;

  public startData;


  public minDate = null;
  public datePickerConfig = null;

  public subscriptionRenderFirst = new Subscription();
  public renderFirstDay = true;
  public lastDayRender = 0;

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  constructor(
    public router: Router,
    public mapService: MapService,
    public renderer: Renderer2,
    public tripService: TripService,
    public toolSetService: ToolSetService,
    public directionService: DirectionService,
    public storage: LocalStorageService,
    public toast: ToastService
  ) {
  }

  ngOnInit() {
    this.storage.removeItem('Guide', 'FirstDayHide');

    this.startData = (!!this.tripService.getStartTrip()) ? this.tripService.getStartTrip() : this.toolSetService.getTomorrow();
    this.tripService.setStartTrip(this.startData);
    const startArray = this.startData.split('.');
    const todayDate = new Date(parseInt(startArray[2], 10), parseInt(startArray[1], 10) - 1, parseInt(startArray[0], 10));

    this.toolSetService.setCurrentDate(todayDate);

    this.subscription.add(
      this.mapService.toggleMenuItemObs.subscribe(info => {
        if (info.state && info.action === 'infoTrip') {
          this.open();
        } else {
          if (this.toggleFlag && info.action !== 'infoDay') {
            this.hide();
          }
        }
      })
    );

    this.minDate = new Date();

    this.datePickerConfig = {
      format: 'DD.MM.YYYY',
      enableMonthSelector: false,
      showGoToCurrent: true,
      locale: 'pl',
      min: moment(this.minDate)
    };


    this.subscriptionRenderFirst = this.mapService.renderFist.subscribe(res => {
      this.renderFirstDay = res['renderFirst'];
      this.lastDayRender = res['lastDayRender'];
    });
  }

  public onChangeData(event) {

    this.startData = formatDate(event.date, 'dd.MM.yyyy', 'en');
    this.tripService.setStartTrip(this.startData);

    this.toolSetService.setCurrentDate(new Date(event.date));

    this.toggleDataPicker = false;

  }

  /** ukrywa pasek boczny */
  public hide(): void {
    if (this.toggleFlag === true) {
      this.toggleFlag = false;
      this.renderer.addClass(this.navLeft.nativeElement, 'closed');
    }

  }

  /**
   * funkcja pokazuje pasek boczny
   */
  public open(): void {
    this.toggleFlag = true;
    this.mapService.groupMonuments.next(false);
    this.renderer.removeClass(this.navLeft.nativeElement, 'closed');
    this.trip = this.tripService.getTrip();
    this.daysArray = (Array.isArray(this.tripService.getDays())) ? this.tripService.getDays() : [];

    const tmp = this.tripService.getDayMonuments(this.lastDayRender);
    if (Array.isArray(tmp)) {
      this.showOnlyObjectsFun(tmp, this.lastDayRender, 0);
    }
  }

  public hideTrip(event) {
    if (event) {
      this.hide();
    }
  }

  public navigateDayDetails() {
    this.mapService.toggleMenuItemObs.next({state: true, action: 'infoDay'});
  }

  public addDay() {
    const length = this.tripService.getLengthDays();
    if (length < 9) {

      const obj = this.tripService.checkLastMonument(length - 1);

      const lengthOfLastDay = (Array.isArray(this.tripService.getDayMonuments(length - 1))) ?
        this.tripService.getDayMonuments(length - 1).length : 0;

      if (!!obj && (lengthOfLastDay >= 2)) {
        this.tripService.addNewDayWithAccomondation(obj);
        this.daysArray = this.tripService.getDays();
        this.mapService.tripDaysObserver.next(this.tripService.getLengthDays());
      } else {
        this.tripService.addNewDay();
        this.daysArray = this.tripService.getDays();
        this.mapService.tripDaysObserver.next(this.tripService.getLengthDays());
      }
    } else {
      this.toast.subjectToast.next(ToastMaker.create({
        message: 'Nie można dodać więcej niż 9 dni wycieczki.',
        time: 4000,
        type: 'warning'
      }));
    }
  }

  public selectCurrentDay(dayIndex) {

    this.renderDay.emit(dayIndex);
  }

  public setForAll(id: number) {
    this.tripService.setModeForAll(id);
    this.directionService.directionSubject.next(null);
  }

  public showOnlyObjectsFun(monumets: Array<Monument>, dayIndex, fromTripInfo = 0) {
    const momnetsId = monumets.map(item => {
      return item.id_objects;
    });

    const sendObj = {arrayObj: momnetsId, fromTripInfo: fromTripInfo};
    this.showOnlyObjects.emit(sendObj);
    this.renderDay.emit(dayIndex);

  }

}
